import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {
  smallView: boolean = false;
  expanded: boolean = false;

  sections = [
    {
      text: `Your consent`,
      id: `1`
    },
    {
      text: `Withdrawal of your consent`,
      id: `2`
    },
    {
      text: `What is a cookie?`,
      id: `3`
    },
    {
      text: `Key concepts`,
      id: `4`,
      subsections: [
        {
          text: `First and third-party cookies`,
          id: `4.1`
        },
        {
          text: `Persistent cookies`,
          id: `4.2`
        },
        {
          text: `Session cookies`,
          id: `4.3`
        }
      ]
    },
    {
      text: `How to delete and block our cookies`,
      id: `5`,
      subsections: [
        {
          text: `Changing your cookie settings`,
          id: `5.1`
        },
        {
          text: `More information`,
          id: `5.2`
        }
      ]
    },
    {
      text: `What cookies do we use and why?`,
      id: `6`
    },
    {
      text: `Additional information about third party analytics in use on UNI`,
      id: `7`
    },
    {
      text: `More information about cookies`,
      id: `8`
    }
  ];

  constructor(public breakpointObserver: BreakpointObserver, public metaService: MetaService) {}

  ngOnInit(): void {
    this.initializeBreakpoints();
    this.updateMetaInfo();
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
    });
  }

  toTop(id: string) {
    var element = document.getElementById(id);
    var headerOffset = this.smallView ? 250 : 150;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    this.expanded = false;
  }

  updateMetaInfo(): void {
    const title = 'Cookie policy - UNI';
    const description = `This cookie policy (“Cookie Policy”) describes the types of cookies we 
      use on UNI and our purposes for using them.`;
    const cover = `https://uni-common-assets.s3.us-east-1.amazonaws.com/logo/uni/uni_logo_393x393.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
