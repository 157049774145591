import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeneralTermsRoutingModule } from './general-terms-routing.module';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonsModule } from '@sparro/app/shared/components/buttons/buttons.module';
import { CardsModule } from '@sparro/app/shared/components/cards/cards.module';
import { ImagesModule } from '@sparro/app/shared/components/images/images.module';
import { LayoutModule } from '@sparro/app/shared/components/layout/layout.module';
import { PipesModule } from '@sparro/app/shared/pipes/pipes.module';
import { LoaderModule } from '@sparro/app/shared/components/loader/loader.module';
import { SharedModule } from '@about/app/shared/shared.module';
import { MaterialModule } from '@sparro/app/material.module';

@NgModule({
  declarations: [CookiePolicyComponent, PrivacyPolicyComponent, TermsOfServiceComponent],
  imports: [
    CommonModule,
    GeneralTermsRoutingModule,
    FlexLayoutModule,
    SharedModule,
    ImagesModule,
    LayoutModule,
    ButtonsModule,
    CardsModule,
    LoaderModule,
    MaterialModule,
    PipesModule
  ]
})
export class GeneralTermsModule {}
