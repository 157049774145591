<div class="main-container margin-auto" fxLayoutAlign="center stretch">
  <ng-container *ngIf="smallView; else largerView">
    <div class="sidenav-container">
      <div class="sidenav">
        <mat-expansion-panel class="expansion-panel mat-elevation-z0" [(expanded)]="expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="title margin-0 font-bold">Contents</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let section of sections">
            <div class="margin-top-2">
              <ng-container *ngIf="section?.subsections; else defaultView">
                <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      class="margin-0 section sparro-grey-800"
                      (click)="toTop(section.id)">
                      {{ section.text }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let subsection of section.subsections">
                    <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                      {{ subsection.text }}
                    </p>
                  </ng-container>
                </mat-expansion-panel>
              </ng-container>
              <ng-template #defaultView>
                <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                  {{ section.text }}
                </p>
              </ng-template>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </div>
    </div>
  </ng-container>

  <ng-template #largerView>
    <div class="sidenav-container relative">
      <div class="sidenav">
        <p class="title margin-0 font-bold section-border">Contents</p>
        <ng-container *ngFor="let section of sections">
          <div class="section-border margin-top-2">
            <ng-container *ngIf="section?.subsections; else defaultView">
              <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title
                    class="margin-0 section sparro-grey-800"
                    (click)="toTop(section.id)">
                    {{ section.text }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let subsection of section.subsections">
                  <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                    {{ subsection.text }}
                  </p>
                </ng-container>
              </mat-expansion-panel>
            </ng-container>
            <ng-template #defaultView>
              <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                {{ section.text }}
              </p>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <div fxLayout="column" class="margin-9" fxLayoutGap="24px">
    <div class="padding-bottom-3 breadcrumb-section">
      <p class="margin-0 desc">
        <a href="https://uni.xyz/policy" class="no-decor-hover">Platform Policies</a> / Business /
        <span class="font-bold">Cookie policy</span>
      </p>
    </div>
    <div class="detail-section margin-top-9">
      <div fxLayout="column" fxLayoutGap="80px">
        <div fxLayout="column" fxLayoutGap="40px">
          <h1 class="margin-0">Cookie policy</h1>
          <p class="margin-0 desc">Effective from August 20, 2022</p>
        </div>
        <p class="margin-0 desc">
          UNI uses cookies, in combination with pixels, local storage objects, and similar devices
          (collectively, “cookies” unless otherwise noted) to distinguish you from other users of
          UNI. This helps us provide you with a good experience, improve our service, and to
          personalize ads and content for you while you use UNI.
          <br /><br />This cookie policy (“Cookie Policy”) describes the types of cookies we use on
          UNI and our purposes for using them.<br /><br />If you have questions about this Cookie
          Policy, or if you would like a detailed list of the cookies that we currently use on UNI,
          please contact us at
          <a href="mailto:privacy@uni.xyz" class="no-decor font-bolder">privacy@uni.xyz</a>. For
          more information about our Privacy practices, please review our
          <a href="https://uni.xyz/policy/general-terms/privacy" class="no-decor font-bolder"
            >Privacy Policy</a
          >.
        </p>

        <div fxLayout="column" fxLayoutGap="48px" id="1">
          <p class="margin-0 title font-bold">1. Your consent</p>
          <p class="margin-0 desc">
            You consent to the placement of cookies on your browser by us and our third-party
            service providers. Please read this Cookie Policy carefully for details about why we use
            cookies and the information they collect from and about you.
          </p>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="2">
          <p class="margin-0 title font-bold">2. Withdrawal of your consent</p>
          <p class="margin-0 desc">
            If you do not wish to accept cookies in connection with your use of UNI, you will need
            to delete, and block or disable cookies via your browser settings; see below for more
            information on how to do this. Please note that disabling cookies will affect the
            functionality of UNI, and may prevent you from being able to access certain features on
            UNI.
          </p>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="3">
          <p class="margin-0 title font-bold">3. What is a cookie?</p>
          <p class="margin-0 desc">
            A cookie is a small file of letters and numbers that we store on your browser. Unless
            you have adjusted your browser settings so that it will refuse cookies, our system will
            issue cookies as soon as you visit UNI.
          </p>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="4">
          <p class="margin-0 title font-bold">4. Key concepts</p>
          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="4.1">
              <p class="margin-0 subtitle font-bold">4.1 First and third-party cookies</p>
              <p class="margin-0 desc">
                Whether a cookie is ‘first’ or ‘third’ party refers to the domain placing the
                cookie.
              </p>
              <ul class="margin-0">
                <li class="desc margin-bottom-4">
                  First-party cookies are those set by a website that is being visited by the user
                  at the time (e.g., cookies placed by
                  <a href="https://www.uni.xyz" class="no-decor font-bolder">https://www.uni.xyz</a
                  >).
                </li>
                <li class="margin-bottom-4 desc">
                  Third-party cookies are cookies that are set by a domain other than that of the
                  website being visited by the user. If a user visits a website and another entity
                  sets a cookie through that website, this would be a third-party cookie.
                </li>
              </ul>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="4.2">
              <p class="margin-0 subtitle font-bold">4.2 Persistent cookies</p>
              <p class="margin-0 desc">
                These cookies remain on a user’s device for the period of time specified in the
                cookie. They are activated each time the user visits the website that created that
                particular cookie.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="4.3">
              <p class="margin-0 subtitle font-bold">4.3 Session cookies</p>
              <p class="margin-0 desc">
                These cookies allow website operators to link the actions of a user during a browser
                session. A browser session starts when a user opens the browser window and finishes
                when they close the browser window. Session cookies are created temporarily. Once
                you close the browser, all session cookies are deleted.
              </p>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="5">
          <p class="margin-0 title font-bold">5. How to delete and block our cookies</p>
          <p class="margin-0 desc">
            Most web browsers allow some control of most cookies through the browser Settings.
            However, if you use your browser settings to block all cookies (including essential
            cookies), you may not be able to access all or parts of UNI. Unless you have adjusted
            your browser setting so that it will refuse cookies, our system will issue cookies as
            soon as you visit UNI.
          </p>
          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="5.1">
              <p class="margin-0 subtitle font-bold">5.1 Changing your cookie settings</p>
              <p class="margin-0 desc">
                The browser settings for changing your cookies settings are usually found in the
                ‘options’ or ‘preferences’ menu of your internet browser. In order to understand
                these settings, the following links may be helpful. Otherwise, you should use the
                ‘Help’ option in your internet browser for more details.
              </p>
              <ul class="margin-0">
                <li class="desc">Cookie Settings in Internet Explorer</li>
                <li class="desc">Cookie Settings in Firefox</li>
                <li class="desc">Cookie Settings in Chrome</li>
                <li class="desc">Cookie Settings in Safari</li>
              </ul>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="5.2">
              <p class="margin-0 subtitle font-bold">5.2 More information</p>
              <p class="margin-0 desc">
                To find out more about cookies, including how to see what cookies have been set and
                how to manage and delete them, visit About Cookies or
                <a href="https://www.allaboutcookies.org" class="no-decor font-bolder">
                  www.allaboutcookies.org</a
                >.
              </p>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
          <i class="icon-info sparro-orange-800"></i>
          <i class="desc"
            >Our Policy of using different types of cookies on UNI and how it improves the
            experience on UNI.</i
          >
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="6">
          <p class="margin-0 title font-bold">6. What cookies do we use and why?</p>
          <div fxLayout="column" fxLayoutGap="16px">
            <p class="margin-0 desc">
              Generally, UNI uses cookies to distinguish you from other users of UNI. This helps us
              to provide you with a good experience when you browse UNI and also allows us to
              improve it.
            </p>
            <p class="margin-0 desc">
              The cookies we may use on UNI may be categorized as follows:
            </p>
            <ul class="margin-0">
              <li class="desc">Strictly necessary</li>
              <li class="desc">Performance</li>
              <li class="desc">Functionality</li>
              <li class="desc">Targeting</li>
            </ul>
            <p class="margin-0 desc">Some cookies may fulfill more than one of these purposes.</p>
            <p class="margin-0 desc">
              ‘Strictly Necessary’ cookies let you move around UNI and use essential features like
              secure areas. Without these cookies, we cannot provide the requested services.
            </p>
            <p class="margin-0 desc">We use these Strictly Necessary cookies to:</p>
            <ul class="margin-0">
              <li class="desc">Identify you as being logged in to UNI and authenticate you.</li>
              <li class="desc">
                Make sure you connect to the right service on UNI when we make any changes to the
                way it works.
              </li>
              <li class="desc">For security purposes.</li>
            </ul>
            <p class="margin-0 desc">
              Accepting these cookies is a condition to using UNI, so if you prevent these cookies
              we can’t guarantee how UNI or the security on UNI will perform during your visit.
            </p>
            <p class="margin-0 desc">
              ‘Performance’ cookies collect information about how you use UNI e.g. which pages you
              visit, and if you experience any errors. These cookies do not collect any information
              that could identify you and are only used to help us improve how UNI works, understand
              the interests of our users, and measure the effectiveness of our Services.
            </p>
            <p class="margin-0 desc">We use performance cookies to:</p>
            <ul class="margin-0">
              <li class="desc">Carry out web analytics: Provide statistics on how UNI is used.</li>
              <li class="desc">
                Perform affiliate tracking: Provide feedback to affiliated entities that one of our
                visitors also visited their site.
              </li>
              <li class="desc">
                Obtain data on the number of users on UNI that have viewed a product or service.
              </li>
              <li class="desc">Help us improve UNI by measuring any errors that occur.</li>
              <li class="desc">Test different designs for UNI.</li>
            </ul>
            <p class="margin-0 desc">
              Some of our performance cookies are managed for us by third parties.
            </p>
            <p class="margin-0 desc">
              ‘Functionality’ cookies are used to provide services or to remember settings to
              improve your visit.
            </p>
            <p class="margin-0 desc">We use ‘Functionality’ cookies for such purposes as:</p>
            <ul class="margin-0">
              <li class="desc">
                Remember Settings you’ve applied such as layout, text size, preferences and colors.
              </li>
              <li class="desc">
                Remember if we’ve already asked you if you want to fill in a survey.
              </li>
              <li class="desc">
                Remember if you have engaged with a particular component or list on UNI so that it
                won’t repeat
              </li>
              <li class="desc">Show you when you’re logged on UNI.</li>
              <li class="desc">To provide and show embedded video content.</li>
              <li class="desc">Some of these cookies are managed for us by third parties.</li>
            </ul>
            <p class="margin-0 desc">
              ‘Targeting’ cookies are used to track your visit to UNI, as well other websites, apps,
              and online services, including the pages you have visited and the links you have
              followed, which allows UNI to display targeted ads to you on UNI.
            </p>
            <p class="margin-0 desc">We may use targeting cookies to:</p>
            <ul class="margin-0">
              <li class="desc">Display targeted ads within UNI.</li>
              <li class="desc">
                To improve how we provide personalized services, and to measure the success of it on
                UNI.
              </li>
            </ul>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
          <i class="icon-info sparro-orange-800"></i>
          <i class="desc"
            >Our Policy of third party analytics using information on UNI adhering to their privacy
            policy.</i
          >
        </div>
        <div fxLayout="column" fxLayoutGap="48px" id="7">
          <p class="margin-0 title font-bold">
            7. Additional information about third party analytics in use on UNI
          </p>
          <div fxLayout="column" fxLayoutGap="16px">
            <p class="margin-0 desc">
              <span class="font-bolder">Facebook Connect. </span>
              For more information about what Facebook collects when you use Facebook buttons on
              UNI, please see Facebook data policy (https://www.facebook.com/terms.php).
            </p>
            <p class="margin-0 desc">
              <span class="font-bolder">Twitter. </span>
              For more information about what Twitter collects when you use Twitter buttons on UNI
              or visit pages on UNI that include these buttons, please see
              https://twitter.com/en/Privacy.
            </p>
            <p class="margin-0 desc">
              <span class="font-bolder">Google Analytics. </span>
              For more information about Google Analytics cookies, please see Google’s help pages
              and Privacy policy: https://marketingplatform.google.com/about/analytics/terms/us/
            </p>
            <p class="margin-0 desc">
              <span class="font-bolder">Google’s Privacy Policy. </span> Google Analytics Help pages
            </p>
            <p class="margin-0 desc">
              Google has developed the Google Analytics opt-out browser add-on; if you want to
              opt-out of Google Analytics, you can download and install the add-on for your web
              browser here.
            </p>
            <p class="margin-0 desc">
              Google Analytics is a web analytics service provided by Google, Inc. (“Google”).
              Google uses cookies to help us analyze how users use UNI. The information generated by
              the cookie about your use of UNI (including your IP address) will be transmitted to
              and stored by Google on servers in the United States. In case of activation of the IP
              anonymization, Google will truncate/anonymize the last octet of the IP address for
              Member States of the European Union as well as for other parties to the Agreement on
              the European Economic Area. Only in exceptional cases, the full IP address is sent to
              and shortened by Google servers in the United States. On behalf of UNI, Google will
              use this information for the purpose of evaluating your use of UNI, and compiling
              reports on your activity for us and third parties who help operate and provide
              services related to UNI. Google will not associate your IP address with any other data
              held by Google. You may refuse the use of these cookies by selecting the appropriate
              Settings on your browser as discussed in this notice. However, please note that if you
              do this, you may not be able to use the full functionality of UNI. Furthermore, you
              can prevent Google’s collection and use of data (cookies and IP address) by
              downloading and installing the browser plug-in available under
              https://tools.google.com/dlpage/….
            </p>
            <p class="margin-0 desc">
              Further information concerning the terms and conditions of use and data privacy for
              Google Analytics can be found at http://www.google.com/analytics/… or at
              https://www.google.de/intl/en_uk….
            </p>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="8">
          <p class="margin-0 title font-bold">8. More information about cookies</p>
          <div fxLayout="column" fxLayoutGap="16px">
            <p class="margin-0 desc font-bolder">Internet Advertising Bureau</p>
            <p class="margin-0 desc">
              A guide to behavioral advertising and online privacy that has been produced by the
              internet advertising industry can be found at:
            </p>
            <p class="margin-0 desc font-bolder">Your Online Choices | EDAA</p>
            <p class="margin-0 desc font-bolder">
              International Chamber of Commerce United Kingdom
            </p>
            <p class="margin-0 desc">
              <span class="font-bolder">Google Analytics. </span> For more information about Google
              Analytics cookies, please see Google’s help pages and Privacy policy:
            </p>
            <p class="margin-0 desc">
              Information on the ICC (UK) UK cookie guide can be found on the ICC website section:
            </p>
            <p class="margin-0 desc">http://www.international-chamber…</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-divider></mat-divider>
