<div class="main-container margin-auto" fxLayoutAlign="center stretch">
  <ng-container *ngIf="smallView; else largerView">
    <div class="sidenav-container">
      <div class="sidenav">
        <mat-expansion-panel class="expansion-panel mat-elevation-z0" [(expanded)]="expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="title margin-0 font-bold">Contents</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let section of sections">
            <div class="margin-top-2">
              <ng-container *ngIf="section?.subsections; else defaultView">
                <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      class="margin-0 section sparro-grey-800"
                      (click)="toTop(section.id)">
                      {{ section.text }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let subsection of section.subsections">
                    <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                      {{ subsection.text }}
                    </p>
                  </ng-container>
                </mat-expansion-panel>
              </ng-container>
              <ng-template #defaultView>
                <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                  {{ section.text }}
                </p>
              </ng-template>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </div>
    </div>
  </ng-container>

  <ng-template #largerView>
    <div class="sidenav-container relative">
      <div class="sidenav">
        <p class="title margin-0 font-bold section-border">Contents</p>
        <ng-container *ngFor="let section of sections">
          <div class="section-border margin-top-2">
            <ng-container *ngIf="section?.subsections; else defaultView">
              <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title
                    class="margin-0 section sparro-grey-800"
                    (click)="toTop(section.id)">
                    {{ section.text }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let subsection of section.subsections">
                  <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                    {{ subsection.text }}
                  </p>
                </ng-container>
              </mat-expansion-panel>
            </ng-container>
            <ng-template #defaultView>
              <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                {{ section.text }}
              </p>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <div fxLayout="column" class="margin-9" fxLayoutGap="24px">
    <div class="padding-bottom-3 breadcrumb-section">
      <p class="margin-0 desc">
        <a href="https://uni.xyz/policy" class="no-decor-hover">Platform Policies</a> / Business /
        <span class="font-bold">Privacy policy</span>
      </p>
    </div>
    <div class="detail-section margin-top-9">
      <div fxLayout="column" fxLayoutGap="80px">
        <div fxLayout="column" fxLayoutGap="40px">
          <h1 class="margin-0">Privacy policy</h1>
          <p class="margin-0 desc">Effective from August 20, 2022</p>
        </div>
        <div fxLayout="column" fxLayoutGap="24px">
          <p class="margin-0 desc">
            We understand that your privacy is important, and we take it very seriously. Our
            “Privacy Policy” describes our policies and procedures on the collection, use,
            disclosure, and sharing of your information when you use UNI. We will not use or share
            your information with anyone except as described in this Privacy Policy. This Privacy
            Policy applies to activities by UNIEGIS NETWORK Private Limited and its affiliates and
            subsidiaries (collectively “UNI,” “we” or “us”).
          </p>
          <p class="margin-0 desc">
            We are committed to being transparent with you about the data we collect about you and
            how it is used and shared. By using our Services, you consent to our use of your data
            under this Privacy Policy.
          </p>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
          <i class="icon-info sparro-orange-800"></i>
          <i class="desc"
            >A gist of the type of information that we collect on UNI and how we collect this
            information.</i
          >
        </div>
        <div fxLayout="column" fxLayoutGap="48px" id="1">
          <p class="margin-0 title font-bold">1. The information we collect</p>
          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="1.1">
              <p class="margin-0 subtitle font-bold">1.1 Registration</p>
              <p class="margin-0 desc">
                To create an account, you provide data including your name and email address. We may
                collect additional data from social logins. We collect information directly from
                individuals, from third parties, and automatically through the UNI.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.2">
              <p class="margin-0 subtitle font-bold">1.2 Account and profile information</p>
              <p class="margin-0 desc">
                When you create your profile on UNI, we collect your name, contact information, and
                other information you provide, such as your area of interest, your education, work
                experience, skills, photo, city or area, etc. Your name, photo, and any other
                information that you choose to add to your public-facing profile will be available
                for viewing to users on UNI. Once you activate a profile, other users will be able
                to see in your profile certain information about your activity on UNI, such as a
                project you published, ideas you discussed, discussions that you initiated, your
                followers and who you follow, topics of interest to you, and the information you
                list as credentials. For more information about your choices for publicly displayed
                information, see the section below about Your choices.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="1.3">
              <p class="margin-0 subtitle font-bold">1.3 Your content</p>
              <p class="margin-0 desc">
                We collect and store the information and content that you post on UNI, including
                your projects, ideas, discussions, and comments. Your Content, date and time stamps,
                and all associated comments are publicly viewable on UNI, along with your name. This
                also may be indexed by search engines and be republished elsewhere on the Internet
                in accordance with our Terms of Service. For more information about what you can
                change, see the below section on Your Choices.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.4">
              <p class="margin-0 subtitle font-bold">1.4 Communications</p>
              <p class="margin-0 desc">
                When you communicate with us (via email, phone, through UNI, subscription on our
                website, or otherwise), we may maintain a record of your communication and start a
                conversation if required.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.5">
              <p class="margin-0 subtitle font-bold">
                1.5 Integrated service provider and linked networks
              </p>
              <p class="margin-0 desc">
                If you elect to connect your UNI account to another online service provider, such as
                a social networking service (“Integrated Service Provider”), you will be allowing us
                to pass to and receive from the Integrated Service Provider your log-in information
                and other user data. You may elect to sign in or sign up on UNI through a linked
                network like Facebook or Google (each a “Linked Network”). If you elect to sign up
                through a Linked Network, you will be asked to enable sharing of your contacts with
                UNI. If you agree to this sharing, in order to facilitate a more connected
                experience, we may send notices informing your contacts you have joined UNI so that
                you and your contacts can find and follow each other on UNI. The specific
                information we may collect varies by Integrated Service Provider, but the
                permissions page for each will describe the relevant information. Integrated Service
                Providers control how they use and share your information; you should consult their
                respective Privacy policies for information about their practices.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.6">
              <p class="margin-0 subtitle font-bold">
                1.6 Automatically collected information about your activity
              </p>
              <p class="margin-0 desc">
                We use cookies, log files, pixel tags, local storage objects, and other tracking
                technologies to automatically collect information about your activities, such as
                your searches, page views, date and time of your visit, and other information about
                your use of UNI. We also collect and may store information that your computer or
                mobile device provides to us in connection with your use of UNI, such as your
                browser type, type of computer or mobile device, browser language, IP address,
                mobile carrier, unique device identifier, location, and requested and referring
                URLs. We also receive information when you view content on or otherwise interact
                with UNI, even if you have not created an account. For more information, see the
                Cookies, Pixels, and Tracking section below.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.7">
              <p class="margin-0 subtitle font-bold">1.7 Third parties</p>
              <p class="margin-0 desc">
                We may receive information about you from third parties, such as other users,
                partners (like competition organizing agency), or our affiliated companies. For
                example, other users may share or disclose information about you, such as when they
                mention you in their content, reply to Your Content, or share information about Your
                Content via other sites or services. We may also collect additional information
                about our “Users” and “Visitors” from third-party apps (like Google Analytics,
                Facebook Pixel, or through the Ad Services we run with third parties).
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.8">
              <p class="margin-0 subtitle font-bold">1.8 Shared information</p>
              <p class="margin-0 desc">
                We may collect information about you from your shared contacts who may invite you
                using our invitation service. An employer/teammate may also provide information
                about their employees or contractors who make use of these Services. For example, we
                will get contact information for “Organization Page” administrators and for
                authorizing users of our premium Services, such as our recruiting, sales, or
                learning products.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.9">
              <p class="margin-0 subtitle font-bold">1.9 Other</p>
              <p class="margin-0 desc">
                Our Services are dynamic, and we often introduce new features, which may require the
                collection of new information. If we collect materially different personal data or
                materially change how we use your data, we will notify you and may also modify this
                Privacy Policy.
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
          <i class="icon-info sparro-orange-800"></i>
          <i class="desc"
            >An information about how we use your data and its integration with our platform.</i
          >
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="2">
          <p class="margin-0 title font-bold">2. How we use your information</p>
          <div fxLayout="column" fxLayoutGap="16px">
            <p class="margin-0 desc">
              How we use your personal data will depend on which Services you use, how you use those
              Services, and the choices you make in your Settings. We use the data that we have
              about you to provide, support, personalize and make our Services more relevant and
              useful to you and others. We do not sell your personally identifying information –
              such as your name and contact information – to third parties to use for their own
              marketing purposes.
            </p>
            <p class="margin-0 desc">UNI uses the information we collect:</p>
          </div>
          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="2.1">
              <p class="margin-0 subtitle font-bold">2.1 Network</p>
              <p class="margin-0 desc">
                We will use data about you (such as profiles you have viewed or data provided
                through address book uploads or partner integration) to suggest connections for you
                and others (e.g. Members who share your contacts) and enable you to invite others to
                become a Member and connect with you. You can also opt to allow us to use your
                precise location or proximity to suggest other users in your region to connect with
                you. We use your location accuracy to tailor the content and information that we may
                send or display to you on UNI, to offer location customization, personalized help,
                and instructions, and to otherwise personalize your experiences while using UNI.
              </p>
              <p class="margin-0 desc">
                It is your choice whether to invite someone to connect with you; your invitation
                will include your name, photo, network, and contact information. We will send
                invitation reminders to the person you invited. You can choose whether to share your
                own list of connections with your connections or not.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.2">
              <p class="margin-0 subtitle font-bold">2.2 Information</p>
              <p class="margin-0 desc">
                Our Services allow you to stay informed about news events and ongoing ideas from
                UNI. Our Services also allow you to recommend relevant content across our Services,
                and suggest skills you may have to add to your profile, and skills that you might
                need to pursue your next opportunity.
              </p>
              <p class="margin-0 desc">
                For marketing and promotional purposes, such as to send you news and newsletters,
                special offers, and promotions, or to otherwise contact you about products or
                information we think may interest you, including information about third-party
                products and services.
              </p>
              <p class="margin-0 desc">
                We use your content, activity and other data, including your name and picture, to
                provide notices to your network and others. For example, subject to your Settings we
                may notify others that you have updated your profile, uploaded a project, took a
                social action (like, appreciate, discussed, followed, shared) made followed someone.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.3">
              <p class="margin-0 subtitle font-bold">2.3 Career</p>
              <p class="margin-0 desc">
                Our services will allow you to explore careers, evaluate educational opportunities,
                and seek out, and be found for, career opportunities. Your profile can be found by
                those looking to hire (for a job or a specific task) or be hired by you. We will use
                your data to recommend jobs and show you and others who have similar roles,
                industries, skill sets, and connections. We may use your profile and activity to
                recommend jobs to you and you to recruiters.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.4">
              <p class="margin-0 subtitle font-bold">2.4 Collaboration</p>
              <p class="margin-0 desc">
                Our Services allow you to collaborate with colleagues and search for potential
                clients, customers, partners, and others to do business with. Our Services allow you
                to communicate with others and create a working diagram to collaborate on a design
                project. Subject to your Settings, we scan messages to provide “bots” or similar
                tools that facilitate tasks such as scheduling work, maintaining your calendar,
                sending automated messages on your behalf, or recommending next steps.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.5">
              <p class="margin-0 subtitle font-bold">2.5 Communications</p>
              <p class="margin-0 desc">
                OWe will contact you through email, notices posted on our websites or apps, messages
                to your Inbox, and other ways through our Services, including text messages and push
                notifications. We will send you messages about the availability of our Services,
                security, or other service-related issues. We also send messages about how to use
                the Services, network updates, reminders, job suggestions, upcoming competitions,
                and promotional messages from us and our partners. You may change your communication
                preferences at any time. Please be aware that you cannot opt out of receiving
                service messages from us, including security and legal notices.
              </p>
              <p class="margin-0 desc">
                We also enable communications between you and others through our Services, including
                for example invitations, Inbox, discussions, ideas, and messages between
                connections.
              </p>
              <p class="margin-0 desc">
                We may also use the information to track the success of our communications and
                marketing and to gather metrics we provide to third parties or our business
                partners.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.6">
              <p class="margin-0 subtitle font-bold">2.6 Marketing</p>
              <p class="margin-0 desc">
                We use data and content about our users to track the success of our communications
                and marketing and to gather metrics we provide to third parties or our business
                partners.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.7">
              <p class="margin-0 subtitle font-bold">2.7 Developing services and research</p>
              <ul class="margin-0">
                <li class="margin-bottom-4 desc">
                  <span class="font-bolder">Service development:</span> We use data, including
                  public feedback, to conduct research and development for the further development
                  of our Services in order to provide you and others with a better, more intuitive,
                  and personalized experience, drive User growth and engagement with our Services,
                  and help to make UNI better and make the design process more open.
                </li>
                <li class="margin-bottom-4 desc">
                  <span class="font-bolder">Other research:</span> We use the data available to us
                  to research the latest trends in various fields of design, job opportunities,
                  skill sets needed for these jobs, and policies that help bridge the gap in various
                  industries and geographical areas. In some cases, we work with trusted third
                  parties to perform this research, under controls that are designed to protect your
                  privacy. We publish or allow others to publish this aggregated data rather than
                  anyone’s personal data.
                </li>
                <li class="margin-bottom-4 desc">
                  <span class="font-bolder">Surveys:</span> Polls and surveys are conducted by us
                  and others through out Services. You are not obligated to respond to polls or
                  surveys and you have choices about the information you provide.
                </li>
              </ul>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.8">
              <p class="margin-0 subtitle font-bold">2.8 Customer support</p>
              <p class="margin-0 desc">
                We use data including your communications to provide you with the services we offer
                on UNI, communicate with you about your use of UNI, respond to your inquiries,
                provide troubleshooting, and for other customer service purposes.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.9">
              <p class="margin-0 subtitle font-bold">2.9 Security and investigations</p>
              <p class="margin-0 desc">
                We use your data, including communication, to comply with legal obligations, as part
                of our general business operations, and for other business administration purposes
                if we think it’s necessary to investigate possible fraud or other violation of our
                Terms of Service or this Privacy Policy and/or attempts to harm fellow Users.
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
          <i class="icon-info sparro-orange-800"></i>
          <i class="desc">
            Places where we share the data, how we protect your data, and amendments you can make to
            your data.
          </i>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="3">
          <p class="margin-0 title font-bold">3. How we share your information</p>
          <p class="margin-0 desc">
            We share information as set forth below, and where individuals have otherwise consented:
          </p>
          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="3.1">
              <p class="margin-0 subtitle font-bold">3.1 Profile</p>
              <p class="margin-0 desc">
                Your profile is fully visible to all Users of our Services. As detailed in our Help,
                in your Settings you may control how an individual or the public sees your
                information. You have Privacy Settings for each of the information that is being
                displayed to the public on UNI. Your name and profile picture will be visible to all
                the participants by default.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="3.2">
              <p class="margin-0 subtitle font-bold">3.2 Your content</p>
              <p class="margin-0 desc">
                Through our services users on UNI can see your content if they are following tags
                associated with your content, they are following you or your project. We may also
                recommend popular projects may include yours on UNI and show it to others. We also
                show aggregated activities of yours on UNI to the people who are following you.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="3.3">
              <p class="margin-0 subtitle font-bold">3.3 Service providers</p>
              <p class="margin-0 desc">
                We may share your information with third-party service providers who use this
                information to perform services for us, such as payment processors, hosting
                providers, auditors, advisors, consultants, customer service and support providers,
                as well as those who assist us in providing UNI associated services.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="3.4">
              <p class="margin-0 subtitle font-bold">3.4 Affiliates</p>
              <p class="margin-0 desc">
                The information collected about you may be accessed by or shared with subsidiaries
                and affiliates of UNI, whose use and disclosure of your personal information is
                subject to this Privacy Policy.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="3.5">
              <p class="margin-0 subtitle font-bold">3.5 Business transfers</p>
              <p class="margin-0 desc">
                We may disclose or transfer information, including personal data, as part of any
                merger, sale, and transfer of our assets, acquisition or restructuring of all or
                part of our business, bankruptcy, or similar event.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="3.6">
              <p class="margin-0 subtitle font-bold">3.6 Legally required</p>
              <p class="margin-0 desc">
                We may disclose your information if we are required to do so by law.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="3.7">
              <p class="margin-0 subtitle font-bold">3.7 Protection of rights</p>
              <p class="margin-0 desc">
                We may disclose information where we believe it's necessary to respond to claims,
                asserted against us or, comply with legal processes (e.g., subpoenas or warrants),
                enforce or administer our Agreements and Terms, for fraud prevention, risk
                assessment, investigation, and protect the rights, property or safety of UNI, its
                users, or others.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="3.8">
              <p class="margin-0 subtitle font-bold">3.8 Your content and activity</p>
              <p class="margin-0 desc">
                Your Content, including your name, profile picture, profile information, and certain
                associated activity information is available to other users of UNI and may be viewed
                publicly. Public viewing includes availability to non-registered visitors and can
                occur when users share Your Content across other sites or services. In addition,
                Your Content may be indexed by search engines if not opted out. See the section
                below about Your Choices for information about how you may change how certain
                information is shared or viewed by others.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="3.9">
              <p class="margin-0 subtitle font-bold">3.9 Anonymized and aggregated data</p>
              <p class="margin-0 desc">
                We may share aggregate or disidentified information with third parties for research
                on Cookie Policy, marketing, analytics, and other purposes, provided such
                information does not identify a particular individual.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="3.10">
              <p class="margin-0 subtitle font-bold">3.10 Cookies, pixels and tracking</p>
              <p class="margin-0 desc">
                We and our third-party providers use cookies, clear GIFs/pixel tags, JavaScript,
                local storage, log files, and other mechanisms to automatically collect and record
                information about your usage and browsing activities on the UNI and across third
                party sites or online services. We may combine this information with other
                information we collect about users. Below, we provide a brief summary of these
                activities. For more detailed information about these mechanisms and how we collect
                activity information, see our Cookie Policy.
              </p>
              <ul class="margin-0">
                <li class="margin-bottom-4 desc">
                  <span class="font-bolder">Cookies:</span> These are small files with a unique
                  identifier that are transferred to your browser through our websites. They allow
                  us to remember users who are logged in, understand how users navigate through and
                  use UNI, and display personalized content and targeted ads (including on
                  third-party sites and applications).
                </li>
                <li class="margin-bottom-4 desc">
                  <span class="font-bolder">Pixels, web beacons, clear GIFs:</span> These are tiny
                  graphics with a unique identifier, similar in function to cookies, that we use to
                  track the online movements of users of UNI and the web pages of users of our
                  Associated organizations, and to personalize content. We also use these in our
                  emails to let us know when they have been opened or forwarded, so we can gauge the
                  effectiveness of our communications.
                </li>
                <li class="margin-bottom-4 desc">
                  <span class="font-bolder">Analytics Tools:</span> We may use internal and
                  third-party analytics tools, including Google Analytics. The third-party analytics
                  companies we work with may combine the information collected with other
                  information they have independently collected from other websites and/or other
                  online products and services. Their collection and use of information are subject
                  to their own Privacy policies.
                </li>
              </ul>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="3.11">
              <p class="margin-0 subtitle font-bold">3.11 How we protect your information</p>
              <p class="margin-0 desc">
                The security of your information is important to us. UNI has implemented safeguards
                to protect the information we collect. However, no website or Internet transmission
                is completely secure. We urge you to take steps to keep your personal data safe,
                such as choosing strong passwords and keeping it private, as well as logging out of
                your user account, and closing your web browser when finished using UNI.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="3.12">
              <p class="margin-0 subtitle font-bold">3.12 Access and amend your information</p>
              <p class="margin-0 desc">
                You may update or correct your account information at any time by logging in to your
                account. You may also make a number of other adjustments to Settings or the display
                of information about you as described in more detail in the following section about
                Your Choices.
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
          <i class="icon-info sparro-orange-800"></i>
          <i class="desc"
            >Your obligations and choices to maintain your content and your account on UNI.</i
          >
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="4">
          <p class="margin-0 title font-bold">4. Your choices and obligations</p>
          <p class="margin-0 desc">
            You may, of course, decline to submit information through UNI, in which case we may not
            be able to provide certain services to you. You may also control the types of
            notifications and communications we send, limit the information shared within UNI about
            you, and otherwise amend certain Privacy Settings. Here is some further information
            about some of your choices:
          </p>
          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="4.1">
              <p class="margin-0 subtitle font-bold">4.1 Your content</p>
              <p class="margin-0 desc">
                You may edit or delete the ideas, discussions, and projects that you post at any
                time. Any idea you have discussed may remain on UNI and be edited by other users.
                Any deleted content will be removed from third party sites from which it has been
                shared via UNI’s sharing features; however, we have no control over deletions or
                changes to Your Content if it has been shared manually by others or your teammates.
                When you make edits to Your Content, other users will be able to see the history of
                those edits in your profile activity.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="4.2">
              <p class="margin-0 subtitle font-bold">4.2 Adult content</p>
              <p class="margin-0 desc">
                In your profile’s Privacy Settings, you can elect whether to receive adult content
                only if you are 18 years old.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="4.3">
              <p class="margin-0 subtitle font-bold">4.3 Emails and communications</p>
              <p class="margin-0 desc">
                When you join UNI by signing up for an account or creating a profile, as part of the
                service, you will receive UNI containing content that we believe may match your
                interests. As permitted by law, you are also opting to receive other emails from us
                that may be of interest to you as a user of the UNI. You can manage your email and
                notice preferences in your account profile Settings, under your Emails and
                Notifications Settings, but note that you cannot opt out of receiving certain
                administrative or legal notices from us. If you opt out of receiving emails about
                recommendations or other information we think may interest you, we may still send
                you transactional e-mails about your account or any services you have requested or
                received from us. It may take up to 30 business days for us to process opt-out
                requests.
              </p>
              <p class="margin-0 desc">
                Additionally, by joining UNI, you are agreeing to receive feedback and
                communications from third parties within UNI, including third parties who may
                comment on your postings within UNI. In your profile, under your Privacy Settings,
                you can adjust whether to allow people to comment on your answers and posts. You can
                also adjust permissions about who you allow to send you messages on UNI.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="4.4">
              <p class="margin-0 subtitle font-bold">4.4 Followers</p>
              <p class="margin-0 desc">
                You can block the ability of another UNI user to follow you by selecting the setting
                for this in the other user’s profile. You can change whether you follow other users
                on UNI or not.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="4.5">
              <p class="margin-0 subtitle font-bold">4.5 Topics</p>
              <p class="margin-0 desc">
                You can change topics that you follow or that your profile lists as areas that you
                know about.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="4.6">
              <p class="margin-0 subtitle font-bold">4.6 Credentials</p>
              <p class="margin-0 desc">
                You can change the credentials that are displayed in your profile or in connection
                with a specific answer.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="4.7">
              <p class="margin-0 subtitle font-bold">4.7 Indexed search</p>
              <p class="margin-0 desc">
                In your Privacy Settings, you can control whether your profile and name are indexed
                by search engines. Changes to Privacy Settings may only apply on a going-forward
                basis; for example, your name (e.g., answers and profile) that has already been
                indexed by search engines may remain indexed for a period of time even after you
                have turned off indexing, as implementing this change is outside our control.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="4.8">
              <p class="margin-0 subtitle font-bold">4.8 Deleted or deactivated account</p>
              <p class="margin-0 desc">
                If you choose to Delete Your Account in your Privacy Settings, then all of Your
                Content except your ideas will be removed from public visibility on UNI, and it may
                not be restored by us, even if you change your mind. If you choose to Deactivate
                Your Account, then you will no longer receive any communications from us, and users
                will not be able to interact with you; however, Your Content will remain on the UNI,
                once you deactivate your account, you can reactivate it any time by choosing to log
                in.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="4.9">
              <p class="margin-0 subtitle font-bold">4.9 Linked networks</p>
              <p class="margin-0 desc">
                You may connect or disconnect your Linked Networks, such as Facebook, through your
                profile page. Once you disconnect it, we will no longer receive information from
                that Linked Network going forward; we may still maintain the information we have
                already collected.
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
          <i class="icon-info sparro-orange-800"></i>
          <i class="desc"
            >The Company undertakes to respect the confidentiality of Your Personal Data and to
            guarantee You can exercise Your rights.
          </i>
        </div>
        <div fxLayout="column" fxLayoutGap="48px" id="5">
          <p class="margin-0 title font-bold">5. Your rights under the GDPR</p>
          <p class="margin-0 desc">
            You have the right under this Privacy Policy, and by law, if you are within the EU, to:
          </p>
          <ul class="margin-0">
            <li class="margin-bottom-4 desc">
              <span class="font-bolder">Request access to your personal data.</span> The right to
              access, update or delete the information We have on You. Whenever made possible, you
              can access, update or request deletion of Your Personal Data directly within Your
              account settings section. If you are unable to perform these actions yourself, please
              contact Us to assist You. This also enables You to receive a copy of the Personal Data
              We hold about You.
            </li>
            <li class="margin-bottom-4 desc">
              <span class="font-bolder"
                >Request correction of the personal data that we hold about you.</span
              >
              You have the right to have any incomplete or inaccurate information We hold about You
              corrected.
            </li>
            <li class="margin-bottom-4 desc">
              <span class="font-bolder">Object to processing of your personal data.</span> This
              right exists where We are relying on a legitimate interest as the legal basis for Our
              processing and there is something about Your particular situation, which makes You
              want to object to our processing of Your Personal Data on this ground. You also have
              the right to object where We are processing Your Personal Data for direct marketing
              purposes.
            </li>
            <li class="margin-bottom-4 desc">
              <span class="font-bolder">Request erasure of your personal data.</span> You have the
              right to ask Us to delete or remove Personal Data when there is no good reason for Us
              to continue processing it.
            </li>
            <li class="margin-bottom-4 desc">
              <span class="font-bolder">Request the transfer of your personal data.</span> We will
              provide to You, or to a third-party You have chosen, Your Personal Data in a
              structured, commonly used, machine-readable format. Please note that this right only
              applies to automated information which You initially provided consent for Us to use or
              where We used the information to perform a contract with You.
            </li>
            <li class="margin-bottom-4 desc">
              <span class="font-bolder">Withdraw your consent.</span> You have the right to withdraw
              Your consent on using your Personal Data. If You withdraw Your consent, We may not be
              able to provide You with access to certain specific functionalities of the Service.
            </li>
          </ul>
        </div>

        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
          <i class="icon-info sparro-orange-800"></i>
          <i class="desc">Here are some general information about your privacy on UNI.</i>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="6">
          <p class="margin-0 title font-bold">6. Other important information</p>

          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="6.1">
              <p class="margin-0 subtitle font-bold">6.1 Transferring your data</p>
              <p class="margin-0 desc">
                UNI is headquartered in NCT India and has operations, entities, and service
                providers throughout the world. As such, we and our service providers may transfer
                your personal data to, or access it in, jurisdictions that may not provide
                equivalent levels of data protection as your home jurisdiction. We will take steps
                to ensure that your personal data receives an adequate level of protection in the
                jurisdictions in which we process it, including through appropriate written data
                processing Terms and/or data transfer agreements.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="6.2">
              <p class="margin-0 subtitle font-bold">6.2 Deletion of your data</p>
              <p class="margin-0 desc">
                You have the right to request the deletion of Your Personal Data, subject to certain
                exceptions. You can choose to delete your data by visiting Setting > security >
                Delete account. Once We receive and confirm Your request, We will delete (and direct
                Our Service Providers to delete) Your personal information from our records, unless
                an exception applies within 7 days. We may deny Your deletion request if retaining
                the information is necessary for Us or Our Service Providers to:
              </p>
              <ul class="margin-0">
                <li class="margin-bottom-4 desc">
                  Complete the transaction for which We collected the personal information, provide
                  a good or service that You requested, take actions reasonably anticipated within
                  the context of our ongoing business relationship with You, or otherwise perform
                  our contract with You.
                </li>
                <li class="margin-bottom-4 desc">
                  Detect security incidents, protect against malicious, deceptive, fraudulent, or
                  illegal activity, or prosecute those responsible for such activities.
                </li>
                <li class="margin-bottom-4 desc">
                  Debug products to identify and repair errors that impair existing intended
                  functionality.
                </li>
                <li class="margin-bottom-4 desc">
                  Exercise free speech, ensure the right of another consumer to exercise their free
                  speech rights, or exercise another right provided for by law.
                </li>
                <li class="margin-bottom-4 desc">
                  Engage in public or peer-reviewed scientific, historical, or statistical research
                  in the public interest that adheres to all other applicable ethics and privacy
                  laws, when the information’s deletion may likely render impossible or seriously
                  impair the research’s achievement if You previously provided informed consent.
                </li>
                <li class="margin-bottom-4 desc">
                  Enable solely internal uses that are reasonably aligned with consumer expectations
                  based on Your relationship with Us.
                </li>
                <li class="margin-bottom-4 desc">Comply with a legal obligation.</li>
                <li class="margin-bottom-4 desc">
                  Make other internal and lawful uses of that information that are compatible with
                  the context in which You provided it.
                </li>
              </ul>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="6.3">
              <p class="margin-0 subtitle font-bold">6.3 Children’s privacy</p>
              <p class="margin-0 desc">
                We do not knowingly collect or solicit personal information from anyone under the
                age of 13 or knowingly allow such persons to register. If we become aware that we
                have collected personal information from a child under the relevant age without
                parental consent, we take steps to delete that information.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="6.4">
              <p class="margin-0 subtitle font-bold">6.4 Do-not-track signals</p>
              <p class="margin-0 desc">
                Please note we do not change system behavior within UNI in response to browser
                requests not to be tracked. You may, however, disable certain tracking by third
                parties.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="6.5">
              <p class="margin-0 subtitle font-bold">6.5 Links to other websites</p>
              <p class="margin-0 desc">
                UNI may contain links to third party sites or online services. We are not
                responsible for the practices of such third parties, whose information practices are
                subject to their own policies and procedures, not to this Privacy Policy.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="6.6">
              <p class="margin-0 subtitle font-bold">6.6 Contact us</p>
              <p class="margin-0 desc">
                If you have any questions about our practices or this Privacy Policy, please contact
                us at Privacy@uni.xyz, or send mail to: <br /><br />
                UNI
                <br /><br />
                Attn: Privacy<br />
                305-A, Yamuna -7, D6<br />
                Vasant Kunj, New Delhi -110070
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="6.7">
              <p class="margin-0 subtitle font-bold">6.7 Changes to our privacy policy</p>
              <p class="margin-0 desc">
                If we change our Privacy Policies and procedures, we will post those changes on this
                page. If we make any changes to this Privacy Policy that materially change how we
                treat your personal information, we will endeavor to provide you with reasonable
                notice of such changes, such as via notification on UNI or to your email address of
                record, and where required by law, we will obtain your consent or give you the
                opportunity to opt-out of such changes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-divider></mat-divider>
