import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  smallView: boolean = false;
  expanded: boolean = false;

  sections: any = [
    {
      text: `The information we collect`,
      id: `1`,
      subsections: [
        {
          text: `Registration`,
          id: `1.1`
        },
        {
          text: `Account and profile information`,
          id: `1.2`
        },
        {
          text: `Your content`,
          id: `1.3`
        },
        {
          text: `Communications`,
          id: `1.4`
        },
        {
          text: `Integrated service provider and linked networks`,
          id: `1.5`
        },
        {
          text: `Automatically collected information about your activity`,
          id: `1.6`
        },
        {
          text: `Third parties`,
          id: `1.7`
        },
        {
          text: `Shared information`,
          id: `1.8`
        },
        {
          text: `Other`,
          id: `1.9`
        }
      ]
    },
    {
      text: `How we use your information`,
      id: `2`,
      subsections: [
        {
          text: `Network`,
          id: `2.1`
        },
        {
          text: `Information`,
          id: `2.2`
        },
        {
          text: `Career`,
          id: `2.3`
        },
        {
          text: `Collaboration`,
          id: `2.4`
        },
        {
          text: `Communications`,
          id: `2.5`
        },
        {
          text: `Marketing`,
          id: `2.6`
        },
        {
          text: `Developing services and research`,
          id: `2.7`
        },
        {
          text: `Customer support`,
          id: `2.8`
        },
        {
          text: `Security and investigations`,
          id: `2.9`
        }
      ]
    },
    {
      text: `How we share your information`,
      id: `3`,
      subsections: [
        {
          text: `Profile`,
          id: `3.1`
        },
        {
          text: `Your content`,
          id: `3.2`
        },
        {
          text: `Service providers`,
          id: `3.3`
        },
        {
          text: `Affiliates`,
          id: `3.4`
        },
        {
          text: `Business transfers`,
          id: `3.5`
        },
        {
          text: `Legally required`,
          id: `3.6`
        },
        {
          text: `Protection of rights`,
          id: `3.7`
        },
        {
          text: `Your content and activity`,
          id: `3.8`
        },
        {
          text: `Anonymized and aggregated data`,
          id: `3.9`
        },
        {
          text: `Cookies, pixels and tracking`,
          id: `3.10`
        },
        {
          text: `How we protect your information`,
          id: `3.11`
        },
        {
          text: `Access and amend your information`,
          id: `3.12`
        }
      ]
    },
    {
      text: `Integrated service provider`,
      id: `4`,
      subsections: [
        {
          text: `Your content`,
          id: `4.1`
        },
        {
          text: `Adult content`,
          id: `4.2`
        },
        {
          text: `Emails and communications`,
          id: `4.3`
        },
        {
          text: `Followers`,
          id: `4.4`
        },
        {
          text: `Topics`,
          id: `4.5`
        },
        {
          text: `Credentials`,
          id: `4.6`
        },
        {
          text: `Indexed search`,
          id: `4.7`
        },
        {
          text: `Deleted or deactivated account`,
          id: `4.8`
        },
        {
          text: `Linked networks`,
          id: `4.9`
        }
      ]
    },
    {
      text: `Your rights under the GDPR`,
      id: `5`
    },
    {
      text: `Other important information`,
      id: `6`,
      subsections: [
        {
          text: `Transferring your data`,
          id: `6.1`
        },
        {
          text: `Deletion of your data`,
          id: `6.2`
        },
        {
          text: `Children’s privacy`,
          id: '6.3'
        },
        {
          text: `Do-not-track signals`,
          id: `6.4`
        },
        {
          text: `Links to other websites`,
          id: `6.5`
        },
        {
          text: `Contact us`,
          id: '6.6'
        },
        {
          text: `Changes to our privacy policy`,
          id: `6.7`
        }
      ]
    }
  ];

  constructor(public breakpointObserver: BreakpointObserver, public metaService: MetaService) {}

  ngOnInit(): void {
    this.initializeBreakpoints();
    this.updateMetaInfo();
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
    });
  }

  toTop(id: string) {
    var element = document.getElementById(id);
    var headerOffset = this.smallView ? 250 : 150;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    this.expanded = false;
  }

  updateMetaInfo(): void {
    const title = 'Privacy policy - UNI';
    const description = `We are committed to being transparent with you about the data we collect 
      about you and how it is used and shared. By using our Services, you consent to our use of 
      your data under this Privacy Policy.`;
    const cover = `https://uni-common-assets.s3.us-east-1.amazonaws.com/logo/uni/uni_logo_393x393.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
