import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {
  smallView: boolean = false;
  expanded: boolean = false;

  sections: any = [
    {
      text: `User agreement`,
      id: `1`,
      subsections: [
        {
          text: `Contract`,
          id: `1.1`
        },
        {
          text: `Users and visitors`,
          id: `1.2`
        },
        {
          text: `Change of terms`,
          id: `1.3`
        }
      ]
    },
    {
      text: `Use of UNI`,
      id: `2`,
      subsections: [
        {
          text: `Who can use it`,
          id: `2.1`
        },
        {
          text: `Registration`,
          id: `2.2`
        },
        {
          text: `Privacy policy`,
          id: `2.3`
        },
        {
          text: `Notices and service messages`,
          id: `2.4`
        },
        {
          text: `Sharing of information`,
          id: `2.5`
        },
        {
          text: `Competitions and awards`,
          id: `2.6`
        },
        {
          text: `Acceptable use policy`,
          id: `2.7`
        },
        {
          text: `Termination`,
          id: `2.8`
        },
        {
          text: `Changes on UNI`,
          id: `2.9`
        },
        {
          text: `Feedback`,
          id: `2.10`
        }
      ]
    },
    {
      text: `Content rights & responsibilities`,
      id: `3`,
      subsections: [
        {
          text: `Definition of your content`,
          id: `3.1`,
          innerSections: [
            {
              text: `Definition of your work`,
              id: `3.1.1`
            },
            {
              text: `Definition of ideas`,
              id: `3.1.2`
            },
            {
              text: `Definition of discussions`,
              id: `3.1.3`
            }
          ]
        },
        {
          text: `Ownership`,
          id: `3.2`
        },
        {
          text: `Content rights & responsibilities`,
          id: `3.3`
        },
        {
          text: `Our content and service availability`,
          id: `3.4`,
          innerSections: [
            {
              text: `Definition of our content and materials.`,
              id: `3.4.1`
            },
            {
              text: `Featured content`,
              id: `3.4.2`
            },
            {
              text: `Data`,
              id: `3.4.3`
            },
            {
              text: `Service availability`,
              id: `3.4.4`
            },
            {
              text: `Other content, sites and apps`,
              id: `3.4.5`
            },
            {
              text: `Our license to you`,
              id: `3.4.6`
            },
            {
              text: `Permitted uses`,
              id: `3.4.7`
            },
            {
              text: `No endorsement or verification.`,
              id: `3.4.8`
            }
          ]
        },
        {
          text: `Intellectual property rights`,
          id: `3.5`
        }
      ]
    },
    {
      text: `Integrated service provider`,
      id: `4`
    },
    {
      text: `More from UNI`,
      id: `5`,
      subsections: [
        {
          text: `Digests`,
          id: `5.1`
        },
        {
          text: `Business and paid services`,
          id: `5.2`
        },
        {
          text: `Web resources and third-party services`,
          id: '5.3'
        }
      ]
    },
    {
      text: `Competitions on UNI`,
      id: `6`,
      subsections: [
        {
          text: `Hosted competitions on UNI`,
          id: `6.1`,
          innerSections: [
            {
              text: `No Endorsement or verification.`,
              id: `6.1.1`
            },
            {
              text: `Ownership and copyright`,
              id: `6.1.2`
            },
            {
              text: `Disqualification`,
              id: `6.1.3`
            },
            {
              text: `Withdrawal of competition`,
              id: `6.1.4`
            },
            {
              text: `Cancellation`,
              id: `6.1.5`
            },
            {
              text: `Juror’s decision/selection of the winner`,
              id: `6.1.6`
            },
            {
              text: `Publication & promotion`,
              id: `6.1.7`
            },
            {
              text: `Integrity of competition`,
              id: `6.1.8`
            },
            {
              text: `Prizes`,
              id: `6.1.9`
            },
            {
              text: `Registration fee and associate cost`,
              id: `6.1.10`
            },
            {
              text: `Registration fee and associate cost`,
              id: `6.1.11`
            }
          ]
        },
        {
          text: `Listing a competition on UNI`,
          id: `6.2`
        },
        {
          text: `Submit a synopsis on UNI`,
          id: '6.3'
        },
        {
          text: `Institutional access by UNI`,
          id: '6.4'
        }
      ]
    },
    {
      text: `Disclaimers and limitation of liability`,
      id: `7`,
      subsections: [
        {
          text: `No warranty`,
          id: `7.1`
        },
        {
          text: `Exclusion of liability`,
          id: `7.2`
        }
      ]
    },
    {
      text: `Indemnification`,
      id: `8`
    },
    {
      text: `Termination`,
      id: `9`
    },
    {
      text: `Dispute resolution`,
      id: `10`
    },
    {
      text: `General terms`,
      id: `11`
    },
    {
      text: `Contact us`,
      id: `12`
    }
  ];

  constructor(public breakpointObserver: BreakpointObserver, public metaService: MetaService) {}

  ngOnInit(): void {
    this.initializeBreakpoints();
    this.updateMetaInfo();
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
    });
  }

  toTop(id: string) {
    var element = document.getElementById(id);
    var headerOffset = this.smallView ? 250 : 150;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    this.expanded = false;
  }

  updateMetaInfo(): void {
    const title = 'Terms of Service - UNI';
    const description = `UNI offers a place to upload your projects along with the thought process behind 
      them and start discussions with other creative people who would be interested. This enables designers 
      to share their work, get feedback and create new knowledge to make the design more open and available 
      to all.`;
    const cover = `https://uni-common-assets.s3.us-east-1.amazonaws.com/logo/uni/uni_logo_393x393.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
