<div class="main-container margin-auto" fxLayoutAlign="center stretch">
  <ng-container *ngIf="smallView; else largerView">
    <div class="sidenav-container">
      <div class="sidenav">
        <mat-expansion-panel class="expansion-panel mat-elevation-z0" [(expanded)]="expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="title margin-0 font-bold">Contents</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let section of sections">
            <div class="margin-top-2">
              <ng-container *ngIf="section?.subsections; else defaultView">
                <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      class="margin-0 section sparro-grey-800"
                      (click)="toTop(section.id)">
                      {{ section.text }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let subsection of section.subsections">
                    <ng-container *ngIf="subsection?.innerSections; else withoutInnerSection">
                      <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                        <mat-expansion-panel-header>
                          <mat-panel-title
                            class="margin-0 section sparro-grey-800"
                            (click)="toTop(subsection.id)">
                            {{ subsection.text }}
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngFor="let innerSection of subsection.innerSections">
                          <p
                            class="margin-0 section sparro-grey-800"
                            (click)="toTop(innerSection.id)">
                            {{ innerSection.text }}
                          </p>
                        </ng-container>
                      </mat-expansion-panel>
                    </ng-container>
                    <ng-template #withoutInnerSection>
                      <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                        {{ subsection.text }}
                      </p>
                    </ng-template>
                  </ng-container>
                </mat-expansion-panel>
              </ng-container>
              <ng-template #defaultView>
                <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                  {{ section.text }}
                </p>
              </ng-template>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </div>
    </div>
  </ng-container>

  <ng-template #largerView>
    <div class="sidenav-container relative">
      <div class="sidenav">
        <p class="title margin-0 font-bold section-border">Contents</p>
        <ng-container *ngFor="let section of sections">
          <div class="section-border margin-top-2">
            <ng-container *ngIf="section?.subsections; else defaultView">
              <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title
                    class="margin-0 section sparro-grey-800"
                    (click)="toTop(section.id)">
                    {{ section.text }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let subsection of section.subsections">
                  <ng-container *ngIf="subsection?.innerSections; else withoutInnerSection">
                    <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                      <mat-expansion-panel-header>
                        <mat-panel-title
                          class="margin-0 section sparro-grey-800"
                          (click)="toTop(subsection.id)">
                          {{ subsection.text }}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <ng-container *ngFor="let innerSection of subsection.innerSections">
                        <p
                          class="margin-0 section sparro-grey-800"
                          (click)="toTop(innerSection.id)">
                          {{ innerSection.text }}
                        </p>
                      </ng-container>
                    </mat-expansion-panel>
                  </ng-container>
                  <ng-template #withoutInnerSection>
                    <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                      {{ subsection.text }}
                    </p>
                  </ng-template>
                </ng-container>
              </mat-expansion-panel>
            </ng-container>
            <ng-template #defaultView>
              <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                {{ section.text }}
              </p>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <div fxLayout="column" class="margin-9" fxLayoutGap="24px">
    <div class="padding-bottom-3 breadcrumb-section">
      <p class="margin-0 desc">
        <a href="https://uni.xyz/policy" class="no-decor-hover">Platform Policies</a> / Business /
        <span class="font-bold">Terms of service</span>
      </p>
    </div>
    <div class="detail-section margin-top-9">
      <div fxLayout="column" fxLayoutGap="80px">
        <div fxLayout="column" fxLayoutGap="40px">
          <h1 class="margin-0">Terms of service</h1>
          <p class="margin-0 desc">Effective from August 20, 2022</p>
        </div>
        <div fxLayout="column" fxLayoutGap="24px">
          <p class="margin-0 desc">
            <span class="font-bolder"
              >At UNI, we are building a collaborative community of creative people.</span
            >
            UNI offers a place to upload your projects along with the thought process behind them
            and start discussions with other creative people who would be interested. This enables
            designers to share their work, get feedback and create new knowledge to make the design
            more open and available to all.
          </p>
          <p class="margin-0 desc">
            Welcome to UNI! Here is a quick summary of the highlights of our Terms of Service:
          </p>
          <ul class="margin-0">
            <li class="desc margin-0">
              You own the content that you upload and post; you also grant us and other users of UNI
              certain rights and license to use it. The details of these licenses are described in
              Section (3) below.
            </li>
            <li class="desc margin-0">
              You are responsible for the content that you post. This includes ensuring that you
              have the rights needed for you to post the content and that your design does not
              violate the legal rights of another party (for example, defamation) or any applicable
              laws.
            </li>
            <li class="desc margin-0">
              You can repost the content from UNI elsewhere, provided that you attribute the design
              back to UNI and respect the rights of the original poster, including any “CC
              Attribution” designated for the content.
            </li>
            <li class="desc margin-0">
              You can participate in hosted competitions on UNI. Uni hosts competitions in different
              domains with different competition organizers.
            </li>
            <li class="desc margin-0">
              You can take part in the competitions hosted on UNI by registering on the competition
              page and uploading the content on UNI. Participating in the competition will make you
              abide by the criteria of the competition organizing partner. Details are described in
              Section (6) below.
            </li>
            <li class="desc margin-0">
              You can participate in listed competitions on UNI. UNI lists competitions of other
              organizers. UNI is not responsible for the content displayed under the competition
              listing. For details of the content, you must check with the competition organizers.
              Details for the listed competitions are described in Section (6) below.
            </li>
            <li class="desc margin-0">
              You are responsible for the information displayed on your profile. You can enter your
              work, educational, personal information, and information about your social profile.
              You can check your Privacy Settings to maintain what information is visible to the
              public.
            </li>
            <li class="desc margin-0">
              We do not endorse or verify work and content posted by users. Our content and
              materials are provided to you “as is,” without any guarantees.
            </li>
            <li class="desc margin-0">
              You are solely responsible for your own use of the UNI. In case you see any violation
              of intellectual property rights/CC licenses, we may contact the content owner on your
              behalf to settle the issue. You need to write to us for such cases at legal@uni.xyz.
            </li>
            <li class="desc margin-0">
              You agree to follow the rules of UNI. Your use of UNI and its services is also subject
              to our
              <a href="https://uni.xyz/policy/general-terms/privacy" class="no-decor font-bolder"
                >Privacy Policy</a
              >,
              <a href="https://uni.xyz/policy/community/acceptable-use" class="no-decor font-bolder"
                >Acceptable Use Policy</a
              >,
              <a href="https://uni.xyz/policy/licensing/copyright" class="no-decor font-bolder"
                >Copyright Policy</a
              >, and
              <a href="https://uni.xyz/policy/licensing/trademark" class="no-decor font-bolder"
                >Trademark Policy</a
              >.
            </li>
            <li class="desc margin-0">
              We offer tools for you to give feedback and report complaints. If you think someone
              has violated your intellectual property rights, other laws, or UNI’s policies, you can
              initiate a report at the contact us portal or by using our in-product reporting tool
              such as “report”.”
            </li>
          </ul>
          <p class="margin-0 desc">
            “We are pleased that you want to join UNI and encourage you to read the full Terms of
            Service.
          </p>
        </div>
        <div fxLayout="column" fxLayoutGap="48px" id="1">
          <p class="margin-0 title font-bold">1. User agreement</p>
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
            <i class="icon-info sparro-orange-800"></i>
            <i class="desc"
              >You agree that by clicking “Sign up”, Signing up with social platforms or similar,
              registering, accessing, or using our services, you are agreeing to enter into a
              legally binding Contract with UNI.</i
            >
          </div>
          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="1.1">
              <p class="margin-0 subtitle font-bold">1.1 Contract</p>
              <p class="margin-0 desc">
                You agree that by clicking “Sign up”, Signing up with social platforms or similar,
                registering, accessing, or using our services (described below), you are agreeing to
                enter into a legally binding Contract with UNI (even if you are using our Services
                on behalf of a company). If you do not agree to this contract (“Contract” or “User
                Agreement”), do not click “Sign up” (or similar) and do not access or otherwise use
                any of our Services.
              </p>
              <p class="margin-0 desc">
                These terms of service (“Terms”) set forth the agreement (“Agreement”) between you
                and UNIEGIS Network Private Limited, (“UNI” “we” or “us”). It governs your use of
                the products and services we offer through our website, applications, and products
                (collectively “UNI”) as well as through the Creative Commons license (“CC”
                Attribution). Your personal data provided to or collected by or for our Services is
                controlled by UNI and will be governed by the law of NCT, India.
              </p>
              <p class="margin-0 desc">
                Your use of our Services is also subject to our
                <a href="https://uni.xyz/policy/general-terms/privacy" class="no-decor font-bolder">
                  Privacy Policy</a
                >
                and
                <a href="https://uni.xyz/policy/general-terms/cookie" class="no-decor font-bolder"
                  >Cookie Policy</a
                >.
              </p>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              class="info-section">
              <i class="icon-info sparro-orange-800"></i>
              <i class="desc"
                >This Contract applies to uni.xyz, its branded websites like Unfuse, Commun,
                Stunnin, Funkt, Boun, and other UNI-related sites, apps, communications, and other
                services.</i
              >
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.2">
              <p class="margin-0 subtitle font-bold">1.2 Users and visitors</p>
              <p class="margin-0 desc">
                This Contract applies to uni.xyz, its branded websites like Unfuse, Commun, Stunnin,
                Funkt, Boun, and other UNI-related sites, apps, communications, and other services
                that state that they are offered under this Contract (“Services”), including the
                offsite collection of data for those services such as those who use hosted, listed
                competitions on UNI. Registered users of our Services are “Users” and unregistered
                users are “Visitors”. This Contract applies to both.
              </p>
              <p class="margin-0 desc">
                When you register and join UNI or enroll/subscribe for any of our branded websites,
                you become a User. If you have chosen not to register for our services and our
                branded websites, you may access certain features as a visitor.
              </p>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              class="info-section">
              <i class="icon-info sparro-orange-800"></i>
              <i class="desc"
                >We can change these Terms at any time including our Privacy Policy and our Cookie
                Policy from time to time.</i
              >
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="1.3">
              <p class="margin-0 subtitle font-bold">1.3 Change of terms</p>
              <p class="margin-0 desc">
                We can change these Terms at any time, including our Privacy Policy and our Cookie
                Policy from time to time. We keep a historical record of all changes under Archive.
                If a change is material, we’ll let you know before they take effect. By using UNI on
                or after that effective date, you agree to the new Terms. If you don’t agree to
                them, you should delete your account before they take effect; otherwise, your use of
                the site and content will be subject to the new Terms.
              </p>
              <p class="margin-0 desc">
                By using UNI, you agree to these Terms. If you don’t agree to any of the Terms, you
                can’t use UNI.
              </p>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="2">
          <p class="margin-0 title font-bold">2. Use of UNI</p>
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
            <i class="icon-info sparro-orange-800"></i>
            <i class="desc"
              >We can change these Terms at any time, including our Privacy Policy and our Cookie
              Policy from time to time.</i
            >
          </div>
          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="2.1">
              <p class="margin-0 subtitle font-bold">2.1 Who can use it</p>
              <p class="margin-0 desc">
                Use of UNI by anyone under 13 years of age is prohibited. You represent that you are
                at least the age of majority in the jurisdiction where you live or, if you are not,
                your parent or legal guardian must consent to these Terms of Service and affirm that
                they accept this Agreement on your behalf and bear responsibility for your use.
              </p>
              <p class="margin-0 desc">
                At UNI Users are account holders. As a User, you agree to (a) try to choose a strong
                and secure password; (2) keep your password secure and confidential; (3) not
                transfer/sell any part of your account if the service for the same has not been
                provided on our website; and (4) follow the law and our list of Dos and Don’ts. You
                are responsible for anything that happens through your account unless you close it
                or report misuse.
              </p>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              class="info-section">
              <i class="icon-info sparro-orange-800"></i>
              <i class="desc"
                >You agree to provide us with accurate information, including your real name, when
                you create your account on the UNI website.</i
              >
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.2">
              <p class="margin-0 subtitle font-bold">2.2 Registration</p>
              <p class="margin-0 desc">
                When you set up a profile on the UNI, you will be asked to provide certain
                information about yourself. You agree to provide us with accurate information,
                including your real name, when you create your account on the UNI website. We will
                treat the information you provide as part of registration in accordance with our
                Privacy Policy. You should take care in maintaining the confidentiality of your
                password.
              </p>
              <p class="margin-0 desc">
                In case our system detects that the data submitted at registration doesn’t comply
                with our Terms we may ask you to rectify or verify the information.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.3">
              <p class="margin-0 subtitle font-bold">2.3 Privacy policy</p>
              <p class="margin-0 desc">
                Our privacy practices are set forth in our Privacy Policy. By using UNI, you agree
                to accept our Privacy Policy, regardless of whether you are a user or a visitor.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.4">
              <p class="margin-0 subtitle font-bold">2.4 Notices and service messages</p>
              <p class="margin-0 desc">
                You agree that we will provide notices to you in the following ways: (1) a notice
                within the Service, or (2) a message sent to the contact information you provided us
                (e.g., email, mobile number, physical address). You agree to keep your contact
                information up to date.
              </p>
              <p class="margin-0 desc">
                Please review your Settings to control and limit the messages you receive from us.
              </p>
            </div>

            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              class="info-section">
              <i class="icon-info sparro-orange-800"></i>
              <i class="desc"
                >We are not obligated to publish any information or content on our Service and can
                remove it at our sole discretion, with or without notice.</i
              >
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.5">
              <p class="margin-0 subtitle font-bold">2.5 Sharing of information</p>
              <p class="margin-0 desc">
                Our Services allow messaging and sharing of information in many ways, such as your
                profile, your projects, your ideas, your discussions, Direct messaging, and blogs.
                Information and content that you share or post may be seen by other Users or
                Visitors. Where we have made settings available, we will honor the choices you make
                about who can see content or information (e.g., people you have limited/blocked to
                follow your posts).
              </p>
              <p class="margin-0 desc">
                We are not obligated to publish any information or content on our Service and can
                remove it at our sole discretion, with or without notice.
              </p>
            </div>

            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              class="info-section">
              <i class="icon-info sparro-orange-800"></i>
              <i class="desc"
                >When you participate in a hosted competition/award on UNI you abide by the rules of
                the competition organizers.</i
              >
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.6">
              <p class="margin-0 subtitle font-bold">2.6 Competitions and awards</p>
              <p class="margin-0 desc">
                When you participate in a hosted competition/award on UNI, you abide by the rules of
                the competition organizers. You also agree that you are not doing any copyright
                infringement and retain ownership of the copyright and other intellectual property
                you submit on UNI. We will not be responsible for any communication between you and
                third parties, including Users who are currently using UNI.
              </p>
              <p class="margin-0 desc">
                You agree to pay the registration fee if any to participate in the competition and
                to use UNI for the submission and judging of your entries. See Section (6) for
                details.
              </p>
              <p class="margin-0 desc">
                UNI also lists competitions from other organizers. UNI is not responsible for any
                incorrect information displayed on the UNI. You will contact the competition
                organizer in case you find any discrepancy on the same.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.7">
              <p class="margin-0 subtitle font-bold">2.7 Acceptable use policy</p>
              <p class="margin-0 desc">
                In your interaction with others on UNI, you agree to follow the
                <a
                  href="https://uni.xyz/policy/community/acceptable-use"
                  class="font-bolder no-decor">
                  Acceptable Use Policy</a
                >
                at all times.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.8">
              <p class="margin-0 subtitle font-bold">2.8 Termination</p>
              <p class="margin-0 desc">
                You may close your account at any time by going to Account Settings and deleting
                your account. It may take us up to 30 days to completely remove your account. In
                case you log in back into your account in the aforementioned period, your account
                will get reactivated again. We may terminate or suspend your UNI account if you
                violate any UNI policy or for any other reason.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.9">
              <p class="margin-0 subtitle font-bold">2.9 Changes on UNI</p>
              <p class="margin-0 desc">
                We are always trying to improve your experience on the UNI. We may need to add or
                change features and may do so without notice to you. For any material
                changes/features which results in the change of Terms and Privacy, we will notify
                you in prior.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="2.10">
              <p class="margin-0 subtitle font-bold">2.10 Feedback</p>
              <p class="margin-0 desc">
                We welcome your feedback and suggestions about how to improve UNI. Feel free to
                submit feedback at uni.xyz/contact or by dropping us a mail at
                <span class="font-bolder">support@uni.xyz</span>
                By submitting feedback, you agree to grant us the right, at our discretion, to use,
                disclose, and otherwise exploit the feedback, in whole or part, freely and without
                compensation to you.
              </p>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="3">
          <p class="margin-0 title font-bold">3. Content rights & responsibilities</p>
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
            <i class="icon-info sparro-orange-800"></i>
            <i class="desc"
              >At UNI, you can share your work, and ideas and start discussions. These are
              collectively called your content.</i
            >
          </div>
          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="3.1">
              <p class="margin-0 subtitle font-bold">3.1 Definition of your content</p>
              <p class="margin-0 desc">
                UNI enables you to add content in the form of text, photos, embed videos, links, and
                files to share with others. All material that you upload, publish, or display to
                others via UNI will be referred to collectively as “Your Content” You acknowledge
                and agree that, as part of using UNI, your design may be viewed by the public
                including Users and visitors, and may appear in search engines as well depending on
                the creative content licenses assigned to your content. Your content can broadly be
                divided under the following categories:
              </p>
              <p class="margin-0 font-bold inner-section-title" id="3.1.1">
                3.1.1 Definition of your work
              </p>
              <p class="margin-0 desc">
                At UNI, you can showcase your “work” which will be referred to as your “content”,
                this enables you to put your intellectual and creative property on UNI and can be
                viewed publicly. While showcasing your work, you will need to assign the CC
                Attribution (learn more about the CC attribution) to your work; this will govern the
                way your content is being displayed on UNI and outside of it over search engines. CC
                Attribution will also define the rights of reproduction of your content and how it
                will be used over the internet.
              </p>
              <p class="margin-0 desc">
                If your work is not suitable for Minors, you will need to specify the content as
                “adult content” while uploading. Other users can also report about the content and
                upon verification, we may change the attribution to your content and notify you of
                the same. If you think that this is not correct, you may “appeal” for the same.
              </p>

              <p class="margin-0 font-bold inner-section-title" id="3.1.2">
                3.1.2 Definition of ideas
              </p>
              <p class="margin-0 desc">
                You can post ideas in the form of text, images, and embedded videos/audio/other
                media. An idea is a form of question and can be posted by users only. Your idea will
                be public and can be shared over the internet and other social platforms as well, it
                can be re-shared by other Users of UNI as well. Users of UNI can discuss and give
                viewpoints on your ideas.
              </p>

              <p class="margin-0 font-bold inner-section-title" id="3.1.3">
                3.1.3 Definition of discussions
              </p>
              <p class="margin-0 desc">
                You can post discussions on your work in the form of text, supported by images and
                embedded media. You can also initiate discussions on another User’s work. Initiated
                discussions are public and can be shared across UNI, other social media platforms,
                and over the web. Other Users of UNI can participate in your discussion and give
                their viewpoints on it. If the community finds your content/Post inappropriate, UNI
                can take action and remove the content upon the User’s request.
              </p>
            </div>

            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              class="info-section">
              <i class="icon-info sparro-orange-800"></i>
              <i class="desc"
                >As between you and UNI, you, or your licensor, as applicable, retain ownership of
                the copyright and other intellectual property in your content, subject to the
                non-exclusive rights granted to us.</i
              >
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="3.2">
              <p class="margin-0 subtitle font-bold">3.2 Ownership</p>
              <p class="margin-0 desc">
                As between you and UNI, you, or your licensor, as applicable, retain ownership of
                the copyright and other intellectual property in your content, subject to following
                non-exclusive rights granted to us: A worldwide, transferable and sub-licensable
                right to use, copy, modify, distribute, publish and process information and content
                that you provide through our Services, without any further consent, notice and/or
                compensation to you or others. This also means you assume all risks related to it,
                including someone else’s reliance on its accuracy, or claims relating to
                intellectual property or other legal rights.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="3.3">
              <p class="margin-0 subtitle font-bold">3.3 Content rights & responsibilities</p>
              <p class="margin-0 desc">
                As between you and UNI, you own the content and information that you submit or post
                to the Services and you are only granting UNI and our affiliate the following
                non-exclusive license: A worldwide, transferable and sub-licensable right to use,
                copy, modify, distribute, publish and process information and content that you
                provide through our Services, without any further consent, notice and/or
                compensation to you or others. We may also use your content to promote Medium,
                including its products and content. We will not sell your content to third parties
                without your explicit permission. These rights are limited on following ways:
              </p>
              <ul class="margin-0">
                <li class="margin-bottom-4 desc">
                  You can end this license for specific content by deleting such content from the
                  Services or generally by closing your account except (a) to the extent you shared
                  it with others as part of the Service, and they copied, re-shared it, or stored it
                  and (b) for the reasonable time it takes to remove from backup and other systems.
                </li>
                <li class="margin-bottom-4 desc">
                  We will get your consent if we want to give third parties the right to publish
                  your posts beyond the Service. However, other Users and/or Visitors may access and
                  share your content and information, consistent with your choices. In such cases,
                  the right of CC attribution will always remain with you.
                </li>
                <li class="margin-bottom-4 desc">
                  While we may edit and make formatting changes to your content (such as translating
                  it, modifying the size, layout, or file type, or removing metadata), we will not
                  modify the meaning of your expression.
                </li>
                <li class="margin-bottom-4 desc">
                  You are welcome to post content on UNI that you’ve published elsewhere, as long as
                  you have the rights you need to do so. By posting content to UNI, you represent
                  that doing so doesn’t conflict with any other agreement you’ve made with your
                  co-worker, company, friends, or other similar platforms over the Internet.
                </li>
                <li class="margin-bottom-4 desc">
                  By posting content you didn’t create to UNI, you are representing that you have
                  the right to do so. For example, you are posting a work that has been referenced
                  or taken from a public domain, used under license (including a free license, such
                  as Creative Commons), or fair use.
                </li>
                <li class="margin-bottom-4 desc">
                  You accept full responsibility for avoiding infringement of the intellectual
                  property or personal rights of others or violation of laws and regulations in
                  connection with your content. You are responsible for ensuring that your content
                  does not violate our Acceptable Use Policy, Copyright Policy, Trademark Policy,
                  other published policy, or any applicable law or regulation. You agree to pay all
                  royalties, fees, and any other monies owed to any person.
                </li>
                <li class="margin-bottom-4 desc">
                  Because you own your content and information, and we only have non-exclusive
                  rights to it, you may choose to make it available to others, including under the
                  terms of a CC license.
                </li>
                <li class="margin-bottom-4 desc">
                  You also acknowledge and agree that we may preserve your content and may also
                  disclose your content and related information if required to do so by law or in
                  the good faith belief that such preservation or disclosure is reasonably necessary
                  to (a) comply with legal process, applicable laws or government requests; (b)
                  comply with legal process, applicable laws or government requests; (d) detect,
                  prevent, or otherwise address fraud, security or technical issues; or (e) protect
                  the rights, property, or personal safety of UNI, its Users, or the public.
                </li>
                <li class="margin-bottom-4 desc">
                  You understand that we may modify, adapt, or create derivative works from your
                  content in order to transmit, display or distribute it over computer networks,
                  devices, service providers, and in various media. We may also remove or refuse to
                  publish your content, in whole or part, at any time.
                </li>
                <li class="margin-bottom-4 desc">
                  You further give us the permission and authority to act as your non-exclusive
                  agent to take enforcement action against any unauthorized use by third parties of
                  your content outside UNI or in violation of our Terms of Service.
                </li>
              </ul>
            </div>

            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              class="info-section">
              <i class="icon-info sparro-orange-800"></i>
              <i class="desc"
                >We are not obligated to publish any information or content on our Service and can
                remove it at our sole discretion, with or without notice.</i
              >
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="3.4">
              <p class="margin-0 subtitle font-bold">3.4 Our content and service availability</p>

              <p class="margin-0 font-bold inner-section-title" id="3.4.1">
                3.4.1 Definition of our content and materials.
              </p>
              <p class="margin-0 desc">
                All intellectual property in or related to UNI (specifically including, but not
                limited to our software, our associated apps, website, logos but excluding your
                content) is the property of UNI, or its subsidiaries and affiliates, or content
                posted by other users on UNI licensed to us (collectively “Our Content and Service
                availability”).
              </p>
              <p class="margin-0 desc">
                We reserve all rights to change UNI’s look and feel. Some parts of UNI are licensed
                under third-party open source as well as purchased licenses. We also make some of
                our own code available under open source licenses. As for other parts of UNI, you
                may not copy or adapt any portion of our code or visual design elements (including
                logos) without express written permission from UNI unless otherwise permitted by
                law.
              </p>

              <p class="margin-0 font-bold inner-section-title" id="3.4.2">
                3.4.2 Featured content
              </p>
              <p class="margin-0 desc">
                Our editorial team may push some content on top without any monetary promotion to
                maintain the quality of content that is being displayed on UNI. Our team curate
                contents daily, but it is also possible that we may take a few days to push new
                curated content on top. We don’t charge our users and will never do anything to
                promote or feature their content. We do not believe in advertising creative content.
                However, an organization or individual may hire a User on UNI to create content for
                them that can be featured on UNI without us taking any charges/fees for the content.
              </p>

              <p class="margin-0 font-bold inner-section-title" id="3.4.3">3.4.3 Data</p>
              <p class="margin-0 desc">
                All data that UNI collects (“data”) about the use of our website and associated apps
                by you or others is the property of UNI, its subsidiaries, and affiliates. For
                clarity, data does not include your content and is separate from our content and
                materials.
              </p>

              <p class="margin-0 font-bold inner-section-title" id="3.4.4">
                3.4.4 Service availability
              </p>
              <p class="margin-0 desc">
                We may change or discontinue any of our services. We don’t promise to store or keep
                showing any information and content that you’ve posted.
              </p>
              <p class="margin-0 desc">
                At UNI, we store some of your information, including images, media, and text;
                however, we are not providing any active storage service. You agree that we have no
                obligation to store, maintain or provide you a copy of any content or information
                that you or others provide, except to the extent required by applicable law and as
                noted in our Privacy Policy.
              </p>

              <div
                fxLayout="row"
                fxLayoutGap="16px"
                fxLayoutAlign="start center"
                class="info-section">
                <i class="icon-info sparro-orange-800"></i>
                <i class="desc"
                  >Our liability and responsibility to the services that we provide under UNI.</i
                >
              </div>

              <p class="margin-0 font-bold inner-section-title" id="3.4.5">
                3.4.5 Other content, sites and apps
              </p>
              <p class="margin-0 desc">
                By using the services, you may encounter content or information that might be
                inaccurate, incomplete, delayed, misleading, illegal, offensive, or otherwise
                harmful. UNI generally does not review content provided by our Users or others. You
                agree that we are not responsible for others' (including other Users’) content or
                information. We cannot always prevent this misuse of our services, and you agree
                that we are not responsible for any such misuse. You also acknowledge the risk that
                you, your work, your team, or your organization may be mistakenly associated with
                content about others when we let followers know you or your organization were
                mentioned in the news. You may opt out of this feature.
              </p>
              <p class="margin-0 desc">
                To enable a functioning community, we have Rules to ensure usernames are distributed
                and used fairly, we have a Username Policy. Under our Trademark Policy, we’ll
                investigate any use of another’s Trademark and respond appropriately.
              </p>
              <p class="margin-0 desc">
                You are responsible for deciding if you want to access or use third-party apps or
                sites that link from our Services. If you allow a third-party app or site to
                authenticate you or connect with your UNI account, that app or site can access
                information on UNI related to you and your connections. Third-party apps and sites
                have their own legal Terms and Privacy Policies, and you may be giving others
                permission to use your information in ways we would not. Except to the limited
                extent it may be required by applicable law, UNI is not responsible for these other
                sites and apps – use these at your own risk. Please see our Privacy Policy.
              </p>

              <div
                fxLayout="row"
                fxLayoutGap="16px"
                fxLayoutAlign="start center"
                class="info-section">
                <i class="icon-info sparro-orange-800"></i>
                <i class="desc"
                  >Licenses we grant to you under UNI to use your, our, and others’ content within
                  and outside UNI.</i
                >
              </div>
              <p class="margin-0 font-bold inner-section-title" id="3.4.6">
                3.4.6 Our license to you
              </p>
              <ul class="margin-0">
                <li class="margin-bottom-4 desc">
                  We grant you a limited, non-exclusive license to use and access Our Content,
                  Services, and data as made available to you on UNI in connection with your use of
                  our services, subject to the terms and conditions of this Agreement.
                </li>
                <li class="margin-bottom-4 desc">
                  UNI gives you a worldwide, royalty-free, revocable, non-assignable, and
                  non-exclusive license to re-post Our content and Materials anywhere on the web
                  provided that: (a) the user who created the content has not explicitly marked the
                  content as not for reproduction under the CC license. (b) you do not modify the
                  content unless mentioned otherwise; (c) you attribute UNI by name in readable text
                  and with a human and machine-followable link (an HTML <> anchor tag) linking back
                  to the page displaying the original source of the content on https://uni.xyz on
                  every page that contains our content and materials; (d) upon request, either by
                  UNI or a user, you remove the user’s name from content where a user has an
                  objection. e.g. (discussions, team members) (e) upon request either by UNI or by a
                  user who contributed to the content, you make a reasonable effort to update a
                  particular piece of content to the latest version on UNI; and (f) upon request,
                  either by UNI or by a user who contributed to the content that has been deleted or
                  marked as not for reproduction under CC attribution. (g) you don’t republish more
                  than a small portion of our content. In exercising these rights, you may not
                  implicitly or explicitly assert any connection with, sponsorship, or endorsement
                  by UNI or any UNI user, without separate, express prior written permission from
                  us.
                </li>
                <li class="margin-bottom-4 desc">
                  You are not entitled to run any active promotion on UNI or use its services to
                  promote content that does not belong to you. You will also not use UNI to promote
                  your company or its services except for you and your company’s intellectual
                  property. We may push your content down on our feed if we find it to be an active
                  promotion and acts as a spamming tool to misuse our services. We reserve the right
                  to push curated content.
                </li>
                <li class="margin-bottom-4 desc">
                  You will only use assigned services to promote your business and content such as
                  listing competition on UNI.
                </li>
                <li class="margin-bottom-4 desc">
                  We may terminate our license to you at any time for any reason. We have the right
                  but not the obligation to refuse to distribute any content on UNI or to remove
                  content. Except the rights and licenses granted in these Terms, we reverse all
                  other rights and grant no other rights or licenses, implied or otherwise.
                </li>
                <li class="margin-bottom-4 desc">
                  You may not do, or try to do, the following: (a) access or tamper with non-public
                  areas of the Services, our computer systems, or the systems of our technical
                  providers; (b) access or search the Services by any means other than the currently
                  available, published interfaces (e.g., APIs) that we provide; (3) forge any TCP/IP
                  packet header or any part of the header information in any email or posting, or in
                  any way use the Services to send altered, deceptive, or false source-identifying
                  information; or (4) interfere with, or disrupt, the access of any user host, or
                  network, including sending a virus, overloading, flooding, spamming, mail-bombing
                  the Services, or by scripting the creation of content or accounts in such a manner
                  as to interfere with or create an undue burden on the Services.
                </li>
                <li class="margin-bottom-4 desc">
                  You agree that we may access, store and use any information that you provide in
                  accordance with the Terms of the Privacy Policy and your choices (including
                  Settings).
                </li>
              </ul>
              <p class="margin-0 desc">
                By submitting suggestions or other feedback regarding our Services to UNI, you agree
                that UNI can use and share (but does not have to) such feedback for any purpose
                without compensation to you.
              </p>
              <p class="margin-0 desc">
                You agree to only provide content or information that does not violate the law or
                anyone’s rights (including intellectual property rights). You also agree that your
                profile information will be truthful. UNI may be required by law to remove certain
                information or content in certain countries.
              </p>
              <div
                fxLayout="row"
                fxLayoutGap="16px"
                fxLayoutAlign="start center"
                class="info-section">
                <i class="icon-info sparro-orange-800"></i>
                <i class="desc"
                  >Use of UNI with an automated bot, scraping tool, data mining tool, bulk
                  downloading tool, etc.</i
                >
              </div>

              <p class="margin-0 font-bold inner-section-title" id="3.4.7">3.4.7 Permitted uses</p>
              <p class="margin-0 desc">
                If you operate a search engine, web crawler, bot, scraping tool, data mining tool,
                bulk downloading tool, wget utility, or similar data gathering or extraction tool,
                you may access UNI, subject to the following additional rules: a) you must be a
                descriptive user agent header; b) you must follow robots.txt at all times; c) your
                access must not adversely affect any aspect of UNI functioning; and d) you must make
                it clear how to contact you, either in your user agent string or on your website if
                you have one.
              </p>
              <p class="margin-0 desc">
                Additionally, these services should be handled while keeping the risk of
                Intellectual property rights in mind. While crawling, scraping, and mining data, you
                might be violating the rules of the services if the CC Attribution of the Content
                has not been followed.
              </p>

              <p class="margin-0 font-bold inner-section-title" id="3.4.8">
                3.4.8 No endorsement or verification.
              </p>
              <p class="margin-0 desc">
                Please note that UNI contains access to third-party content, products, and services,
                and it offers interactions with third parties. Participation or availability on UNI
                does not amount to endorsement or verification by us. We make no warranties or
                representations about the accuracy, completeness, or timeliness of any content
                posted on UNI by anyone.
              </p>
              <div
                fxLayout="row"
                fxLayoutGap="16px"
                fxLayoutAlign="start center"
                class="info-section">
                <i class="icon-info sparro-orange-800"></i>
                <i class="desc"
                  >UNI reserves all of its intellectual property rights in the services.</i
                >
              </div>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="3.5">
              <p class="margin-0 subtitle font-bold">3.5 Intellectual property rights</p>
              <p class="margin-0 desc">
                UNI reserves all of its intellectual property rights in the services. Using the
                services doesn’t give you any ownership in our services or the content or
                information made available through our services. Trademarks and logos used in
                connection with the services are the Trademarks of their respective owners UNI logo
                and other Trademarks, service marks, graphics, and logos used for our services are
                Trademarks or registered Trademarks of UNI.
              </p>
              <p class="margin-0 desc">
                If you believe content on UNI violates UNI’s Acceptable Use Policy or otherwise
                violates applicable law (apart from copyright or Trademark violation) or other UNI
                policies, you may send us a mail, report, or contact us to notify us about such
                content.
              </p>
              <p class="margin-0 desc">
                We have no obligation to delete content that you personally may find objectionable
                or offensive. We endeavor to respond promptly to requests for content removal,
                consistent with our policies and applicable law.
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
          <i class="icon-info sparro-orange-800"></i>
          <i class="desc">UNI reserves all of its intellectual property rights in the services.</i>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="4">
          <p class="margin-0 title font-bold">4. Integrated service provider</p>
          <p class="margin-0 desc">
            You may enable another online service provider, such as a social networking service
            (“Integrated Service Provider”), to be directly integrated into your account on UNI. By
            enabling an integrated service, you are allowing us to pass to, and receive from, the
            Integrated Service Provider your log-in information related to you and your use of
            integrated services within UNI, please see our Privacy Policy. Note that your use of any
            Integrated Service Provider and its own handling of your data and information is
            governed solely by their Terms of Use, Privacy Policies, and other policies.
          </p>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="5">
          <p class="margin-0 title font-bold">5. More from UNI</p>

          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="5.1">
              <p class="margin-0 subtitle font-bold">5.1 Digests</p>
              <p class="margin-0 desc">
                When you sign up for UNI, the service includes receipt of a digest of works,
                discussions, ideas, and profiles of some users and teams that may be of interest to
                you. You can opt out of receipt of the digest, and adjust other communication
                Settings, by going to “Email and Notification” Settings in your account profile, as
                further described in our Privacy Policy.
              </p>
              <p class="margin-0 desc">
                You may unsubscribe from receiving emails from us, however we may still send you
                transactional emails or mail that we feel are important for you to continue using
                UNI.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="5.2">
              <p class="margin-0 subtitle font-bold">5.2 Business and paid services</p>
              <p class="margin-0 desc">
                UNI is dedicated to solving real-life problems for designers and creative people. To
                do so, UNI provides certain free and paid services (called “Premium Services”), such
                as hosted competitions, posted jobs, royalty-based papers, articles, and certain
                content that our users decide to be paid for. If you wish to partner with us for any
                such business opportunity, you will be required to enter into separate and
                supplemental terms with us about doing business with UNI.
              </p>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              class="info-section">
              <i class="icon-info sparro-orange-800"></i>
              <i class="desc"
                >You have permission to use UNI’s buttons, links, and widgets, subject to these
                Terms of Service.</i
              >
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="5.3">
              <p class="margin-0 subtitle font-bold">5.3 Web resources and third-party services</p>
              <p class="margin-0 desc">
                UNI may also offer you the opportunity to visit links to other websites or to engage
                with third-party products or services. You assume all risks arising out of your use
                of such websites or resources.
              </p>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="6">
          <p class="margin-0 title font-bold">6. Competitions on UNI</p>
          <p class="margin-0 desc">
            To enable collaborations, UNI offers a place to create and participate in competitions
            on UNI. UNI enables competitions on UNI in two ways:
          </p>
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
            <i class="icon-info sparro-orange-800"></i>
            <i class="desc"
              >Competitions on UNI can be hosted by our subsidiaries or a third-party
              organization.</i
            >
          </div>
          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="6.1">
              <p class="margin-0 subtitle font-bold">6.1 Hosted competitions on UNI</p>
              <p class="margin-0 desc">
                Competitions on UNI can be hosted by our subsidiaries or a third-party organization.
                We do check the legitimacy of the organization but cannot guarantee their
                intention/behavior. When participating in the hosted competitions on UNI, you are
                bound by the rules of the organization. We are not responsible for any
                fraudulent/misuse of UNI. You are solely responsible for all its criteria.
                Participating in a hosted competition on UNI also binds the following rules to this
                agreement:
              </p>
              <ul class="margin-0">
                <li class="margin-bottom-4 desc">
                  We reserve the right to change these conditions at any time without prior notice.
                  It will be your responsibility to check these terms and conditions for any changes
                  that have been made to the website.
                </li>
                <li class="margin-bottom-4 desc">
                  We are not responsible for any communications between users of the site and third
                  parties. This also includes designers whose work is published on the site.
                </li>
                <li class="margin-bottom-4 desc">
                  You grant us the permission to share submitted work with third parties, which may
                  include but are not limited to potential employers, other blogs, websites, and
                  social media platforms.
                </li>
                <li class="margin-bottom-4 desc">
                  You may not attempt to gain unauthorized access to any portion or feature of the
                  Site, or any other systems or networks connected to the Site. You may not attempt
                  to obtain any materials, documents, or information through any means not purposely
                  made available through the Site.
                </li>
                <li class="margin-bottom-4 desc">
                  Users who submit work at the request of UNI or its Subsidiary are responsible for
                  reading the Terms of Use as they are presented to them. Once a user submits their
                  work, they grant full publishing privileges to UNI for both electronic and print
                  formats.
                </li>
                <li class="margin-bottom-4 desc">
                  UNI is not responsible for the written or graphic content that is associated with
                  our journal posts. Designers, authors, or creators of any kind will be cited
                  within their published works and retain responsibility that this work is their own
                  and in no way conflicts with the copyright of another individual or organization.
                  It is the responsibility of the original creator or author to verify prior to
                  submission that all submitted work is either original content belonging to the
                  creator or author. By submitting work in any way to UNI, the individual or team
                  accepts full responsibility for the text, images, video footage, and any other
                  form of media that is provided.
                </li>
              </ul>

              <div
                fxLayout="row"
                fxLayoutGap="16px"
                fxLayoutAlign="start center"
                class="info-section">
                <i class="icon-info sparro-orange-800"></i>
                <i class="desc">Our payment gateway providers are PayPal and Razorpay.</i>
              </div>

              <p class="margin-0 font-bold inner-section-title" id="6.1.1">
                7.1.1 No Endorsement or verification.
              </p>
              <p class="margin-0 desc">
                UNI understands the need for the security of your personal details/details related
                to the transaction that you enter on our website and that’s why all the sensitive
                data related to your transaction/card is collected and processed through a secured
                system from one of our partner’s payment gateways. While entering all the data
                related to the card/bank account, please check the green lock sign on the browse.
              </p>
              <p class="margin-0 desc">
                Our payment gateway providers are PayPal and Razorpay. Users can try entering any
                transaction-related information through any of these gateways only. All the
                credit/debit cards are supported on UNI. Kindly enter the same email address/billing
                address through which you will be registering for the competition, failing which our
                system might not be able to recognize the payments and a manual verification for the
                same might be required.
              </p>
              <p class="margin-0 desc">
                Any applicable taxes on the entrance fees will be displayed on the payment portal
                before the payment happens.
              </p>
              <p class="margin-0 desc">
                If you don’t receive a confirmation email of your payment within 24 hours, please
                contact us at support@uni.xyz with all your payment/registration details.
              </p>
              <div
                fxLayout="row"
                fxLayoutGap="16px"
                fxLayoutAlign="start center"
                class="info-section">
                <i class="icon-info sparro-orange-800"></i>
                <i class="desc"
                  >The copyright and ownership of the project will always remain with the author.</i
                >
              </div>

              <p class="margin-0 font-bold inner-section-title" id="6.1.2">
                7.1.2 Ownership and copyright
              </p>
              <ul class="margin-0">
                <li class="margin-bottom-4 desc">
                  The copyright and ownership of the project will always remain with the author. In
                  case reproduced content is submitted, the user will have to take the consent of
                  the actual author of the project, in such cases the user will be responsible for
                  any discrepancies or legal issues that may occur during the competition.
                </li>
                <li class="margin-bottom-4 desc">
                  The entries submitted by the author will, however, be owned by the UNI as well,
                  and we will have the right to distribute/publish/populate data at any place which
                  we see fits best without any notification to the participants.
                </li>
                <li class="margin-bottom-4 desc">
                  Participants will be completely responsible for protecting the intellectual
                  property of any submission to UNI events and competitions.
                </li>
              </ul>
              <div
                fxLayout="row"
                fxLayoutGap="16px"
                fxLayoutAlign="start center"
                class="info-section">
                <i class="icon-info sparro-orange-800"></i>
                <i class="desc"
                  >Submissions that do not comply with the submission guidelines/Eligibility
                  Criteria/Terms and conditions of the website can be barred from participating in
                  the competition without any prior notice.
                </i>
              </div>

              <p class="margin-0 font-bold inner-section-title" id="6.1.3">
                7.1.3 Disqualification
              </p>
              <p class="margin-0 desc">
                Submissions that do not comply with the submission guidelines/Eligibility
                Criteria/Terms and conditions of the website can be barred from participating in the
                competition without any prior notice. Any mark of identification on the submission
                will also result in the disqualification of the entries. Any submission received
                after the given deadline will be automatically disqualified.
              </p>
              <p class="margin-0 desc">
                There will be no tolerance for plagiarism. Unless otherwise stated through the use
                of image or text credit, the submissions must be the original work of the
                participant. In submitting the entries, participants automatically agree that the
                work they have submitted is their work and their work alone unless otherwise
                credited and cited clearly and fully.
              </p>
              <p class="margin-0 desc">
                Any entry which is found guilty of plagiarism will be disqualified immediately,
                reported, and banned from entering any future competitions/ events/publications/
                projects or events hosted by UNI and its partners.
              </p>
              <p class="margin-0 desc">
                UNI reserves the right to disqualify the entry if UNI identifies any kind of
                mismatch between the registration and details and the details received at the time
                of receiving awards (e.g. composition of students and professional teams).
              </p>

              <p class="margin-0 font-bold inner-section-title" id="6.1.4">
                7.1.4 Withdrawal of competition
              </p>
              <p class="margin-0 desc">
                UNI reserves the right to withdraw from the competition without any prior notice to
                the participants. In such a case, the registration fee will be refunded to the
                participants.
              </p>

              <p class="margin-0 font-bold inner-section-title" id="6.1.5">7.1.5 Cancellation</p>
              <p class="margin-0 desc">
                In the case where the participant wishes to cancel/withdraw his participation from
                the competition, participants can write us a mail and inform us of the same. No
                refund of the registration fee will be given in such a case. Exceptional
                circumstances, if any, can be explained to UNI in writing along with the proof. We
                will consider these circumstances at our own discretion.
              </p>
              <div
                fxLayout="row"
                fxLayoutGap="16px"
                fxLayoutAlign="start center"
                class="info-section">
                <i class="icon-info sparro-orange-800"></i>
                <i class="desc"
                  >The decision of the Judges Panel is final, no appeals will be entertained, and no
                  further correspondence shall be entered into.
                </i>
              </div>

              <p class="margin-0 font-bold inner-section-title" id="6.1.6">
                7.1.6 Juror’s decision/selection of the winner
              </p>
              <ul class="margin-0">
                <li class="margin-bottom-4 desc">
                  The decision of the Judges Panel is final, no appeals will be entertained, and no
                  further correspondence shall be entered into.
                </li>
                <li class="margin-bottom-4 desc">
                  If the selected winning Entrant cannot accept, receive, or becomes ineligible for
                  the prize for any reason, the prize shall be awarded to the next highest ranking.
                  Submission of the remaining entries that meet the winning criteria.
                </li>
                <li class="margin-bottom-4 desc">
                  Unless stated otherwise, organizers' association with the Competition judges is
                  not legally binding and does not imply any contract or partnership.
                </li>
                <li class="margin-bottom-4 desc">
                  In cases where the total number of entries goes more than 50, the organizing
                  committee may shortlist up to 50 entries based on the general evaluation criteria
                  upon the juror’s consent.
                </li>
              </ul>

              <p class="margin-0 font-bold inner-section-title" id="6.1.7">
                7.1.7 Publication & promotion
              </p>
              <ul class="margin-0">
                <li class="margin-bottom-4 desc">
                  Publications advertised in the Competition Brief are not binding to the Organizer.
                  The Organizer shall make an effort to follow the announced Publication schedule
                  but cannot be held responsible for Publishers’ decisions.
                </li>
                <li class="margin-bottom-4 desc">
                  Upon request, Entrants agree to participate in Promotional activities and
                  Publicity arrangements with respect to the Competition. The Organizer is
                  authorized to use all statements made by the Entrant in connection with the
                  Competition without additional approval.
                </li>
                <li class="margin-bottom-4 desc">
                  The organizer reserves the right to run promotions on the Website, as well as to
                  discontinue any of its promotions at any time.
                </li>
                <li class="margin-bottom-4 desc">
                  By entering the competition, the Entrant acknowledges and accepts that all aspects
                  of any Submission – including but not limited to narratives, descriptions,
                  details, images, illustrations, and animations – may be used by the Organizer for
                  publicity purposes.
                </li>
              </ul>
              <div
                fxLayout="row"
                fxLayoutGap="16px"
                fxLayoutAlign="start center"
                class="info-section">
                <i class="icon-info sparro-orange-800"></i>
                <i class="desc"
                  >The judging panel will be made aware of an Entrant’s’ identity only after the
                  winners have been selected.
                </i>
              </div>

              <p class="margin-0 font-bold inner-section-title" id="6.1.8">
                7.1.8 Integrity of competition
              </p>
              <p class="margin-0 desc">
                To ensure Entrant anonymity and to protect the integrity of the Competition, upon
                registering with UNI each Entrant will upload their entries on a secured portal. The
                entry will be displayed anonymously to the jurors. However, all information
                collected regarding the Entrant will be stored under their username on UNI and can
                be accessed at any time.
              </p>
              <p class="margin-0 desc">
                All Submissions shall be devoid of any marks, logos, symbols, or signs that may
                identify its authority or source. Any infringement of these rules will lead to
                immediate disqualification.
              </p>
              <p class="margin-0 desc">
                The judging panel will be made aware of an Entrant's identity only after the winners
                have been selected.
              </p>
              <div
                fxLayout="row"
                fxLayoutGap="16px"
                fxLayoutAlign="start center"
                class="info-section">
                <i class="icon-info sparro-orange-800"></i>
                <i class="desc"
                  >Any taxes applicable as per the country of origin will be deducted from the
                  overall prize money. Participants/winners traveling for the event will have to
                  arrange the VISA at their own cost.
                </i>
              </div>

              <p class="margin-0 font-bold inner-section-title" id="6.1.9">7.1.9 Prizes</p>
              <ul class="margin-0">
                <li class="margin-bottom-4 desc">
                  The prize is announced in the Competition Brief for each place separately.
                </li>
                <li class="margin-bottom-4 desc">
                  To make the process smoother, UNI releases the award at the end of every quarter
                  of the year (i.e. March, June, September, and December).
                </li>
                <li class="margin-bottom-4 desc">
                  For the award to be released, the form must be submitted at least 30 days prior to
                  the end of the quarter.
                </li>
                <li class="margin-bottom-4 desc">
                  In the case of the monetary award, upon processing the award may take up to 10
                  business days to get credited to the winner’s account.
                </li>
                <li class="margin-bottom-4 desc">
                  In a competition, a user is entitled only to one award only. Under no
                  circumstances will a person be entitled to receive more than one award in a single
                  competition.
                </li>
                <li class="margin-bottom-4 desc">
                  In the case of non-monetary awards, UNI will share the tracking code with the
                  winners; however, in the case of non-delivery due to an incorrect postal address,
                  UNI will not be liable for the non-delivery.
                </li>
                <li class="margin-bottom-4 desc">
                  Participants can download an e-certificate from their project page on UNI.
                </li>
                <li class="margin-bottom-4 desc">
                  In case there are two winners for the same spot, the prize money will be equally
                  distributed among the winners.
                </li>
                <li class="margin-bottom-4 desc">
                  All transaction charges are recouped from the prize fund.
                </li>
                <li class="margin-bottom-4 desc">
                  Any taxes applicable as per the country of origin of the competition organizer
                  will be deducted from the overall prize money. Participants/winners traveling for
                  the event will have to arrange the VISA at their own cost.
                </li>
              </ul>

              <p class="margin-0 font-bold inner-section-title" id="6.1.10">
                7.1.10 Registration fee and associate cost
              </p>
              <p class="margin-0 desc">
                Entrants must submit the Registration Fee (“Registration Fee”) at the time of
                Registration for the Competition.
              </p>
              <p class="margin-0 desc">
                All costs and disbursements incurred by an Entrant in preparing a Submission are the
                sole responsibility of the Entrant. The registration fee is non-refundable except in
                the case when the Competition is suspended.
              </p>
              <div
                fxLayout="row"
                fxLayoutGap="16px"
                fxLayoutAlign="start center"
                class="info-section">
                <i class="icon-info sparro-orange-800"></i>
                <i class="desc"
                  >By entering the Competition all Entrants grant to the Organizer an unrestricted,
                  worldwide, irrevocable, and royalty-free license to use his/her Submission.
                </i>
              </div>

              <p class="margin-0 font-bold inner-section-title" id="6.1.11">
                7.1.11 Registration fee and associate cost
              </p>
              <p class="margin-0 desc">
                By entering the Competition all Entrants grant to the Organizer an unrestricted,
                worldwide, irrevocable, and royalty-free license (but not the obligation) to use
                his/her Submission as follows: publicly display by any means and in any media now
                and hereafter known, copy, modify, translate and/or adapt, archive and distribute in
                relation to Competitions and Publicity purposes without any further notice or
                remuneration to the Entrant.
              </p>
              <p class="margin-0 desc">
                All content that may go online at any point in time on a social forum/network. The
                author will be notified for the same to specify/choose the creative commons
                licensing of it.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="6.2">
              <p class="margin-0 subtitle font-bold">7.2 Listing a competition on UNI</p>
              <p class="margin-0 desc">
                A third party or organization can list their competition on UNI through our
                submission portal. Although our system verifies the competition, however, we do not
                guarantee the information presented on our portal. You will check the details of the
                competition with the organizing agency through their portal/website/email ID.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="6.3">
              <p class="margin-0 subtitle font-bold">7.3 Submit a synopsis on UNI</p>
              <p class="margin-0 desc">
                A third-party individual or organization termed as a “curator” can submit a
                brief/synopsis on UNI through our Submit a Synopsis section for a competition. UNI
                verifies and publishes the competition depending on the choices that the third party
                or the organization has provided.
              </p>
              <ul class="margin-0">
                <li class="margin-bottom-4 desc">
                  UNI publishes this synopsis as a competition based on multiple factors that are
                  solely decided by the organizing team of UNI.
                </li>
                <li class="margin-bottom-4 desc">
                  UNI reserves the right to discard any synopsis without even notifying the users.
                </li>
                <li class="margin-bottom-4 desc">
                  UNI lists the organization/individual as a curator of the competition and thus may
                  list the public profiles of the curator with the competition on UNI.
                </li>
                <li class="margin-bottom-4 desc">
                  UNI reserves the right to discard briefs on extremely sensitive topics.
                </li>
                <li class="margin-bottom-4 desc">
                  Submitting a synopsis should not be used as a funding program for the cause.
                </li>
                <li class="margin-bottom-4 desc">
                  UNI reserves the right to choose the jurors/judges for the competition prior to
                  the announcement.
                </li>
                <li class="margin-bottom-4 desc">
                  UNI will provide the royalty at the rate of 20% above the prize money collected as
                  a part of the registration fee or from the sponsors to the curators.
                </li>
                <li class="margin-bottom-4 desc">
                  UNI reserves the right to change the terms to submit the synopsis at any time.
                </li>
              </ul>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="6.4">
              <p class="margin-0 subtitle font-bold">7.4 Institutional access by UNI</p>
              <p class="margin-0 desc">
                Institutional access is a collaboration service by UNI provided to universities and
                institutions across the globe to participate in a global design challenge. UNI
                enables certain design challenges to be listed under institutional access depending
                on the nature of the challenge.
              </p>
              <ul class="margin-0">
                <li class="margin-bottom-4 desc">
                  UNI reserves the right to withdraw the feature of institutional access without any
                  prior notice.
                </li>
                <li class="margin-bottom-4 desc">
                  UNI allows only affiliated institutions/schools/universities/colleges to be a part
                  of institutional access.
                </li>
                <li class="margin-bottom-4 desc">
                  UNI can change the participation fee for every institutional access program
                  without any prior notice.
                </li>
                <li class="margin-bottom-4 desc">
                  A third party may get only 1 Pass for institutional access for submitting an
                  approved synopsis.
                </li>
                <li class="margin-bottom-4 desc">
                  Institutional access doesn’t allow universities/mentors to bring people outside
                  their university to be a part of institutional access. Doing so will violate the
                  meaning of institutional access and their entry will be disqualified without
                  serving any notice.
                </li>
                <li class="margin-bottom-4 desc">
                  An institution will not be granted access if a person associated with that
                  institution is part of the jury in the associated competition.
                </li>
                <li class="margin-bottom-4 desc">
                  One mentor can be a part of the multiple institutional access program.
                </li>
                <li class="margin-bottom-4 desc">
                  UNI reserves the right to change the terms of institutional access at any time.
                </li>
                <li class="margin-bottom-4 desc">
                  UNI can discard the access at any time if we find any violation of the terms of
                  service by UNI.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="7">
          <p class="margin-0 title font-bold">8. Disclaimers and limitation of liability</p>

          <div fxLayout="column" fxLayoutGap="40px">
            <p class="margin-0 desc font-bolder">
              PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF UNIEGIS NETWORK
              PRIVATE LIMITED TO YOU.
            </p>
            <p class="margin-0 desc">
              “UNI” MEANS UNIEGIS NETWORK PRIVATE LIMITED, AND ANY SUBSIDIARIES, AFFILIATES, RELATED
              COMPANIES, SUPPLIERS, LICENSORS AND PARTNERS, AND THE OFFICERS, DIRECTORS, EMPLOYEES,
              AGENTS, AND REPRESENTATIVES OF EACH OF THEM. EACH PROVISION BELOW APPLIES TO THE
              MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
            </p>
            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              class="info-section">
              <i class="icon-info sparro-orange-800"></i>
              <i class="desc"
                >This is our disclaimer of legal liability for the quality, safety, or reliability
                of our Services.</i
              >
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="7.1">
              <p class="margin-0 subtitle font-bold">8.1 No warranty</p>
              <p class="margin-0 desc">
                TO THE EXTENT ALLOWED UNDER LAW, UNI AND ITS AFFILIATES (AND THOSE THAT UNI WORKS
                WITH TO PROVIDE THE SERVICES) (A) DISCLAIM ALL IMPLIED WARRANTIES AND
                REPRESENTATIONS (E.G. WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, ACCURACY OF DATA, AND NON-INFRINGEMENT); (B) DO NOT GUARANTEE THAT THE
                SERVICES WILL FUNCTION WITHOUT INTERRUPTION OR ERRORS, AND (C) PROVIDE THE SERVICE
                (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS.
              </p>
              <p class="margin-0 desc">
                SOME LAWS DO NOT ALLOW CERTAIN DISCLAIMERS, SO SOME OR ALL OF THESE DISCLAIMERS MAY
                NOT APPLY TO YOU.
              </p>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              class="info-section">
              <i class="icon-info sparro-orange-800"></i>
              <i class="desc">These are the limits of legal liability we may have to you.</i>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="7.2">
              <p class="margin-0 subtitle font-bold">8.2 Exclusion of liability</p>
              <p class="margin-0 desc">
                TO THE EXTENT PERMITTED UNDER LAW (AND UNLESS UNI HAS ENTERED INTO A SEPARATE
                WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT), UNI AND ITS AFFILIATE (AND THOSE
                THAT UNI WORKS WITH TO PROVIDE THE SERVICES) SHALL NOT BE LIABLE TO YOU OR OTHERS
                FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY
                LOSS OF DATA, OPPORTUNITIES, REPUTATION, PROFITS OR REVENUES, RELATED TO THE
                SERVICES (E.G. OFFENSIVE OR DEFAMATORY STATEMENTS, DOWNTIME OR LOSS, USE OF, OR
                CHANGES TO, YOUR INFORMATION OR CONTENT).
              </p>
              <p class="margin-0 desc">
                UNI MAKES NO PROMISES WITH RESPECT TO, AND EXPRESSLY DISCLAIMS ALL LIABILITY FOR:
                (A) CONTENT POSTED BY ANY USER OR THIRD PARTY; (B) ANY THIRD-PARTY WEBSITE,
                THIRD-PARTY PRODUCT, OR THIRD-PARTY SERVICE LISTED ON OR ACCESSIBLE TO YOU THROUGH
                UNI, INCLUDING AN INTEGRATED SERVICE PROVIDER OR PROFESSIONAL CONTRIBUTOR; (C) THE
                QUALITY OR CONDUCT OF ANY THIRD PARTY YOU ENCOUNTER IN CONNECTION WITH YOUR USE OF
                UNI; OR (D) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR CONTENT.
              </p>
              <p class="margin-0 desc">
                YOUR SOLE REMEDY FOR DISSATISFACTION WITH UNI IS TO STOP USING UNI.
              </p>
              <p class="margin-0 desc">
                WITHOUT LIMITING THE FOREGOING, UNI’S MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES
                OR DAMAGES THAT YOU SUFFER IN CONNECTION WITH UNI OR THIS AGREEMENT IS LIMITED TO
                THE AMOUNT PAID TO UNI IN CONNECTION WITH UNI IN THE SIX (6) MONTHS PRIOR TO THE
                ACTION GIVING RISE TO LIABILITY.
              </p>
              <p class="margin-0 desc">
                THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE BARGAIN BETWEEN YOU AND UNI
                AND SHALL APPLY TO ALL CLAIMS OF LIABILITY (E.G. WARRANTY, TORT, NEGLIGENCE,
                CONTRACT, LAW) AND EVEN IF UNI OR ITS AFFILIATES HAS BEEN TOLD OF THE POSSIBILITY OF
                ANY SUCH DAMAGE, AND EVEN IF THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE.
              </p>
              <p class="margin-0 desc">
                SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR EXCLUSION OF
                LIABILITY FOR CERTAIN TYPES OF DAMAGES. AS A RESULT, THE ABOVE LIMITATIONS OR
                EXCLUSIONS MAY NOT APPLY TO YOU IN WHOLE OR IN PART.
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
          <i class="icon-info sparro-orange-800"></i>
          <i class="desc"
            >Your agreement to indemnify and defend UNI from all third-party claims and costs.</i
          >
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="8">
          <p class="margin-0 title font-bold">9. Indemnification</p>
          <p class="margin-0 desc">
            You agree to release, indemnify, and defend UNI from all third-party claims and costs
            (including reasonable attorneys’ fees) arising out of or related to i) your use of UNI,
            ii) Your Content, iii) your conduct or interactions with other users of UNI, or iv) your
            breach of any part of this Agreement. We will promptly notify you of any such claim and
            will provide you (at your expense) with reasonable assistance in defending the claim.
            You will allow us to participate in the defense and will not settle any such claim
            without our prior written consent. We reserve the right, at our own expense, to assume
            the exclusive defense of any matter otherwise subject to indemnification by you. In that
            event, you will have no further obligation to defend us in that matter.
          </p>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
          <i class="icon-info sparro-orange-800"></i>
          <i class="desc"
            >Both you and UNI may terminate this contract at any time with notice to the other.</i
          >
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="9">
          <p class="margin-0 title font-bold">10. Termination</p>
          <p class="margin-0 desc">
            Both you and UNI may terminate this contract at any time with notice to the other. On
            termination, you lose the right to access or use the Services. The following shall
            survive termination:
          </p>
          <ul class="margin-0">
            <li class="margin-bottom-4 desc">Our rights to use and disclose your feedback.</li>
            <li class="margin-bottom-4 desc">
              User and/or Visitors’ rights to further re-share content and information you shared
              through the Service to the extent copied or re-shared prior to termination.
            </li>
            <li class="margin-bottom-4 desc">Sections 7, 8, 10, and 11 of this Contract.</li>
            <li class="margin-bottom-4 desc">
              Any amounts owed by either party prior to termination remain owed after termination.
            </li>
          </ul>
          <p class="margin-0 desc">You can visit your Settings to close your account.</p>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="10">
          <p class="margin-0 title font-bold">11. Dispute resolution</p>
          <p class="margin-0 desc">
            This Agreement and any action arising out of your use of UNI will be governed by the
            laws of NCT, India without regard to or application of its conflict of law provisions or
            your state or country of residence. We both agree that all of these claims can only be
            litigated in NCT, India, and we each agree to the personal jurisdiction of the courts
            located in NCT, India.
          </p>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
          <i class="icon-info sparro-orange-800"></i>
          <i class="desc">Here are some important details about how to read the Contract.</i>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="11">
          <p class="margin-0 title font-bold">12. General terms</p>
          <p class="margin-0 desc">
            If a court with authority over this Contract finds any part of it not enforceable, you
            and we agree that the court should modify the terms to make that part enforceable while
            still achieving its intent. If the court cannot do that, you and we agree to ask the
            court to remove that unenforceable part and still enforce the rest of this Contract. To
            the extent allowed by law, the English language version of this Contract (including
            additional terms that may be provided by us when you engage with a feature of the
            Services) is the only agreement between us regarding the Services and supersedes all
            prior agreements for the Services.
          </p>
          <p class="margin-0 desc">
            If we don’t act to enforce a breach of this Contract, that does not mean that UNI has
            waived its right to enforce this Contract. You may not assign or transfer this Contract
            (or your membership or use of Services) to anyone without your consent. However, you
            agree that UNI may assign this Contact to its affiliates or a party that buys it without
            your consent. There are no third-party beneficiaries of this Contract.
          </p>
          <p class="margin-0 desc">
            If you access UNI, you acknowledge that this Agreement is between you and UNI only, and
            not with another application service provider or application platform provider (such as
            Apple Inc. or Google Inc.), which may provide you the application subject to its own
            terms. To the extent you access UNI through a mobile device, your wireless carrier’s
            standard charges, data rates, and other fees may apply.
          </p>
          <p class="margin-0 desc">
            You may not assign or transfer this Agreement (or any or your rights or obligations
            under this Agreement) without our prior written consent; any attempted assignment or
            transfer without complying with the foregoing will be void. We may freely assign or
            transfer this Agreement. This Agreement inures to the benefit of and is binding upon the
            parties and their respective legal representatives, successors, and assigns.
          </p>
          <p class="margin-0 desc">
            This agreement does not create a joint venture, agency, partnership, or other forms of
            joint enterprise between you and us. Except as expressly provided herein, neither party
            has the right, power, or authority to create any obligation or duty, express or implied,
            on behalf of the other.
          </p>
          <p class="margin-0 desc">
            No waiver of any terms will be deemed a further or continuing waiver of such terms or
            any other term. Our failure to assert a right or provision under this Agreement will not
            constitute a waiver of such a right or provision.
          </p>
          <p class="margin-0 desc">
            We reserve the right to change the terms of this Contract and will provide you notice if
            we do, and we agree that changes cannot be retroactive. If you don’t agree to these
            changes, you must stop using the Services.
          </p>
          <p class="margin-0 desc">
            You agree that the only way to provide us with legal notice or do any kind of legal
            communication is at the addresses provided in Section 12. We do not guarantee to reply
            to any other mode of communication.
          </p>
        </div>

        <div fxLayout="column" fxLayoutGap="48px" id="12">
          <p class="margin-0 title font-bold">13. Contact us</p>
          <p class="margin-0 desc">
            If you want to send us notices or services of process, please contact us:
          </p>
          <p class="margin-0 desc">
            <a href="https://uni.xyz/contact" class="no-decor font-bolder">ONLINE</a> or by
            <a href="mailto:contact@uni.xyz" class="no-decor font-bolder">MAIL</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-divider></mat-divider>
